import { LanguageEnum } from 'modules/common/enums';
import { UploadResultType } from 'modules/common/types';
import {
	GetSoundtrackType,
	GetSoundtrackResponseType,
	SoundtrackType,
	SoundtrackTypeType,
} from 'modules/soundtrack/types';
import {} from 'modules/soundtrack/types';
import { UploadedSoundtrackInfoType } from 'modules/soundtrack/types/uploadedSoundtrackInfo';
import { hmApi } from 'store/api';

export const {
	useGetSoundtrackQuery,
	useGetSoundtracksQuery,
	useGetSoundtrackTypesQuery,
	useGetSoundtrackUploadResultsQuery,
	useLazyGetSoundtrackQuery,
} = hmApi.injectEndpoints({
	endpoints: (builder) => ({
		getSoundtracks: builder.query<GetSoundtrackResponseType, GetSoundtrackType>(
			{
				query: (params) => ({
					url: '/soundtracks',
					params: {
						errorFilter: params.errorFilter,
						page: params.current,
						limit: params.pageSize,
						search: params.search ? params.search : undefined,
						languageId: params.languageId,
						authorIdsFilter: params.authorIdsFilter,
						categoryIdsFilter: params.categoryIdsFilter,
					},
				}),
			}
		),
		getSoundtrack: builder.query<SoundtrackType, { id: number }>({
			query: ({ id }) => ({ url: `/soundtracks/${id}` }),
		}),
		getSoundtrackTypes: builder.query<SoundtrackTypeType[], void>({
			query: () => ({ url: '/soundtracks/types' }),
		}),
		getSoundtrackUploadResults: builder.query<
			UploadResultType<UploadedSoundtrackInfoType>,
			LanguageEnum
		>({
			query: (language) => ({
				url: '/soundtracks/files',
				params: { language },
			}),
		}),
	}),
});

import { FC } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import { TextAreaProps } from 'antd/lib/input';
import TextArea from 'antd/es/input/TextArea';

type ControlledTextAreaProps = {
  useControllerProps: UseControllerProps<any>;
  textAreaProps?: TextAreaProps;
};

export const ControlledTextArea: FC<ControlledTextAreaProps> = ({
	useControllerProps,
	textAreaProps,
}) => {
	const {
		field: { onChange, onBlur, value },
	} = useController(useControllerProps);

	return (
		<TextArea
			{...textAreaProps}
			onBlur={onBlur}
			onChange={onChange}
			value={value}
		/>
	);
};

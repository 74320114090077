import { UploadFile } from 'antd';
import * as yup from 'yup';

export const schema = yup.object({
	nameEng: yup.string(),
	nameHeb: yup.string(),
	isNassi: yup.boolean(),
	fileList: yup.mixed<UploadFile[]>(),
});

export type ValidationSchemaType = yup.InferType<typeof schema>;

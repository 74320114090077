import { AuthorResponseType, AuthorType } from 'modules/author/types';
import { PaginationType } from 'modules/common/types';
import { hmApi } from 'store/api';
export const { useGetAuthorsQuery, useGetAllAuthorsQuery } =
	hmApi.injectEndpoints({
		endpoints: (builder) => ({
			getAuthors: builder.query<
				AuthorResponseType,
				PaginationType & { search?: string }
			>({
				query: ({ page, limit, search }) => ({
					url: 'authors',
					params: { page, limit, search },
				}),
			}),
			getAllAuthors: builder.query<AuthorType[], void>({
				query: () => 'authors/all',
			}),
		}),
	});

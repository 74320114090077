import { Row, Col } from 'antd';
import { ControlledSwitch } from 'modules/common/components';
import { FC } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type PropsType = {
	control: Control<any>;
};

export const TogglesSection: FC<PropsType> = ({ control }) => {
	const { t } = useTranslation('soundtrack', { keyPrefix: 'SoundtrackForm' });

	return (
		<Row gutter={[12, 12]}>
			<Col>
				{t('isAllowDownload')}:{' '}
				<ControlledSwitch
					useControllerProps={{
						name: 'isAllowDownload',
						control,
					}}
				/>
			</Col>
			<Col>
				{t('isDegraded')}:{' '}
				<ControlledSwitch
					useControllerProps={{
						name: 'isDegraded',
						control,
					}}
				/>
			</Col>
			<Col>
				{t('forDistribution')}:{' '}
				<ControlledSwitch
					useControllerProps={{
						name: 'forDistribution',
						control,
					}}
				/>
			</Col>
			<Col>
				{t('hasBookVersion')}:{' '}
				<ControlledSwitch
					useControllerProps={{
						name: 'hasBookVersion',
						control,
					}}
				/>
			</Col>
		</Row>
	);
};

import { Config } from 'config';
import { RoleEnum } from 'modules/auth/enums';
import * as yup from 'yup';

export const schema = yup.object().shape({
	role: yup.string().oneOf(Object.values(RoleEnum)).required(),
	firstName: yup.string().max(255).required(),
	lastName: yup.string().max(255).required(),
	countryCode: yup.string().optional(),
	phoneNumber: yup.string().max(15).optional().nullable(),
	email: yup
		.string()
		.email()
		.required()
		.test('validate-email', 'Invalid email', (value) => {
			return Config.EMAIL_REGEX.test(value);
		}),
	password: yup.string().min(6).max(100).required(),
	repeatPassword: yup
		.string()
		.required()
		.oneOf([yup.ref('password')], 'Passwords must match'),
});

export type ValidationSchemaType = yup.InferType<typeof schema>;

import { Card, Col, Input, Modal, Row } from 'antd';
import { Config } from 'config';
import { Button } from 'modules/common/components';
import { UserForm, UsersTable } from 'modules/user/components';
import { useGetUsersQuery } from 'modules/user/requests/queries';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const Users: FC = () => {
	const { t } = useTranslation('user', { keyPrefix: 'Users' });
	const [isCreateAdminModalVisible, setIsCreateAdminModalVisible] =
		useState(false);
	const [currenPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(Config.DEFAULT_ITEMS_SHOWN_ROWS);
	const [search, setSearch] = useState<string | undefined>();
	const {
		data: userList,
		isFetching: isUserListFetching,
		refetch: refreshUserList,
	} = useGetUsersQuery({
		page: currenPage,
		limit: pageSize,
		search,
	});

	const openCreateAdminModal = () => {
		setIsCreateAdminModalVisible(true);
	};

	const closeCreateAdminModal = () => {
		setIsCreateAdminModalVisible(false);
	};

	return (
		<Card>
			<Row gutter={[0, 24]}>
				<Col span={24}>
					<Row gutter={[24, 0]}>
						<Col flex={'auto'}>
							<Input.Search
								loading={isUserListFetching}
								placeholder={t('searchPlaceholder')}
								onSearch={setSearch}
							/>
						</Col>
						<Col>
							<Button onClick={openCreateAdminModal} type='primary'>
								{t('addUser')}
							</Button>
						</Col>
					</Row>
				</Col>
				<Col span={24}>
					<UsersTable
						users={userList?.items || []}
						page={currenPage}
						pageSize={pageSize}
						total={userList?.count || 0}
						onPageChange={setCurrentPage}
						onPageSizeChange={setPageSize}
						isUserListLoading={isUserListFetching}
						refetchList={refreshUserList}
					/>
				</Col>
			</Row>
			<Modal
				footer={false}
				open={isCreateAdminModalVisible}
				onCancel={closeCreateAdminModal}
			>
				<h3>{t('addUser')}</h3>
				<UserForm
					key={`add-admin-modal${isCreateAdminModalVisible.toString()}`}
					refreshUserList={refreshUserList}
					closeModal={closeCreateAdminModal}
				/>
			</Modal>
		</Card>
	);
};

import { FC } from 'react';
import { Layout as LayoutAntD } from 'antd';
import styles from './styles.module.css';
import { Header } from './Header';
import { Sider } from './Sider';
import { Outlet } from 'react-router-dom';

export const Layout: FC = () => {
	return (
		<LayoutAntD className={styles.layout}>
			<Header />
			<LayoutAntD>
				<Sider />
				<LayoutAntD.Content className={styles.content}>
					<Outlet />
				</LayoutAntD.Content>
			</LayoutAntD>
		</LayoutAntD>
	);
};

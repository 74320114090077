import { Table } from 'modules/common/components';
import { FC, useState } from 'react';
import { useColumns } from './columns';
import { useNavigate } from 'react-router-dom';
import { Card, Col, Row, TableProps } from 'antd';
import { useGetSoundtracksQuery } from 'modules/soundtrack/requests/queries';
import { useLocalizedContent } from 'modules/common/providers';
import { SoundtrackType } from 'modules/soundtrack/types';
import { LanguageIdByName } from 'constants/languageIdByName';
import { PathEnum } from 'modules/common/enums';
import {
	FilesManagementHeader,
	SoundtrackTableHeader,
} from 'modules/soundtrack/components';
import { SoundtrackErrorType } from 'modules/soundtrack/types/soundtrackError';

export const SoundtrackList: FC = () => {
	const [search, setSearch] = useState<string | undefined>();
	const [current, setCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const { locale } = useLocalizedContent();
	const [errorFilter, setErrorFilter] = useState<
		SoundtrackErrorType | undefined
	>();
	const [categoryIdsFilter, setCategoryIdsFilter] = useState<
		number[] | undefined
	>();
	const [authorIdsFilter, setAuthorIdsFilter] = useState<
		number[] | undefined
	>();

	const columns = useColumns();
	const navigate = useNavigate();
	const {
		data: soundtrackList,
		isFetching,
		refetch: refetchSoundtracks,
	} = useGetSoundtracksQuery({
		errorFilter,
		current,
		pageSize,
		search,
		languageId: LanguageIdByName[locale],
		authorIdsFilter,
		categoryIdsFilter,
	});

	const onChange: TableProps<SoundtrackType>['onChange'] = (
		_pagination,
		filters
	) => {
		if (filters.author) {
			setAuthorIdsFilter(filters.author as number[]);
		} else {
			setAuthorIdsFilter(undefined);
		}
	};

	return (
		<Card>
			<Row gutter={[0, 24]}>
				<Col span={24}>
					<FilesManagementHeader refetchSoundtracks={refetchSoundtracks} />
				</Col>
				<Col span={24}>
					<SoundtrackTableHeader
						hasCreateItem
						setCategoryIdsFilter={setCategoryIdsFilter}
						setSearch={setSearch}
						setErrorFilter={setErrorFilter}
						categoryIdsFilter={categoryIdsFilter}
					/>
				</Col>
				<Col span={24}>
					<Table
						onChange={onChange}
						loading={isFetching}
						bordered
						dataSource={soundtrackList?.soundtracks}
						pagination={{
							current: current,
							pageSize: pageSize,
							total: soundtrackList?.total,
							onChange(page, pageSize) {
								setCurrent(page);
								setPageSize(pageSize);
							},
						}}
						onRow={(record) => {
							return {
								onClick: () => {
									navigate(`${PathEnum.SOUNDTRACKS}/${record.id}`);
								},
							};
						}}
						columns={columns}
						rowKey='id'
					/>
				</Col>
			</Row>
		</Card>
	);
};

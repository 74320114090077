import { AddUserType } from 'modules/user/types';
import { hmApi } from 'store/api';

export const { useAddUserMutation, useDeleteUserMutation } =
	hmApi.injectEndpoints({
		endpoints: (build) => ({
			addUser: build.mutation<undefined, AddUserType>({
				query: (body) => ({
					url: 'users',
					method: 'POST',
					body,
				}),
			}),
			deleteUser: build.mutation<undefined, number>({
				query: (id) => ({
					url: `users/${id}`,
					method: 'DELETE',
				}),
			}),
		}),
	});

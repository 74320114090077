import { ApiBuilderType } from 'modules/common/types';
import {
	GetUserMessagesResponseType,
	GetUserMessagesType,
} from 'modules/user-messages/types';

export const getMany = (builder: ApiBuilderType) => {
	return builder.query<GetUserMessagesResponseType, GetUserMessagesType>({
		query: (params) => ({ url: '/user-messages', params }),
	});
};

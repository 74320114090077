import { RoleEnum } from 'modules/auth/enums';
import { FC, PropsWithChildren } from 'react';
import { tokensServices } from 'modules/auth/services';
import { UserType } from 'modules/user/types';

type PropsType = PropsWithChildren<{
	rolesAllowed?: RoleEnum[];
	user?: UserType;
}>;

export const RoleGuard: FC<PropsType> = ({ children, rolesAllowed, user }) => {
	if (user && rolesAllowed?.length && !rolesAllowed.includes(user.role)) {
		tokensServices.removeTokens();
		window.location.reload();
	}

	return <>{children}</>;
};

import { LogoutOutlined } from '@ant-design/icons';
import { Layout as AntdLayout, Button, Col, Row, Switch, theme } from 'antd';
import React, { FC, useContext } from 'react';
import { ColorModeContext } from '../../../../../contexts/color-mode';
import { tokensServices } from 'modules/auth/services';
import { HeaderTitle } from '../HeaderTitle';
import { useSignOutMutation } from 'modules/auth/requests/mutations';
import { useGetUserQuery } from 'modules/auth/requests/queries';

const { useToken } = theme;

export const Header: FC = () => {
	const { token } = useToken();
	const { mode, setMode } = useContext(ColorModeContext);
	const [signOut, { isLoading: isSignOutLoading }] = useSignOutMutation();
	const { data: user } = useGetUserQuery();

	const headerStyles: React.CSSProperties = {
		backgroundColor: token.colorBgElevated,
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		padding: '0px 24px',
		height: '64px',
		position: 'sticky',
		top: 0,
		zIndex: 1,
	};

	const logout = async () => {
		const refreshToken = tokensServices.getRefreshToken();

		refreshToken && (await signOut(refreshToken));

		tokensServices.removeTokens();

		window.location.reload();
	};

	return (
		<AntdLayout.Header style={headerStyles}>
			<Row
				style={{
					width: '100%',
				}}
				align='middle'
				justify='space-between'
			>
				<Col>
					<HeaderTitle />
				</Col>

				<Col>
					<Row justify='center' align='middle' gutter={[10, 0]}>
						<Col>
							<span>
								{user?.firstName} {user?.lastName}
							</span>
						</Col>
						<Col>
							<Switch
								checkedChildren='🌛'
								unCheckedChildren='🔆'
								onChange={() => setMode(mode === 'light' ? 'dark' : 'light')}
								defaultChecked={mode === 'dark'}
							/>
						</Col>
						<Col>
							<Button
								loading={isSignOutLoading}
								icon={<LogoutOutlined />}
								type='text'
								onClick={logout}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</AntdLayout.Header>
	);
};

import { PathEnum } from 'modules/common/enums';
import { UserType } from 'modules/user/types';
import { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

type PropsType = { user?: UserType } & PropsWithChildren;

export const Auth: FC<PropsType> = ({ children, user }) => {
	if (!user) {
		return <Navigate to={PathEnum.SIGN_IN} />;
	}

	return <>{children}</>;
};

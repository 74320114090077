import { SignInType, SignInResponseType } from 'modules/auth/types';
import { hmApi } from 'store/api';

export const { useSignInMutation, useSignOutMutation } = hmApi.injectEndpoints({
	endpoints: (build) => ({
		signIn: build.mutation<SignInResponseType, SignInType>({
			query: (data) => ({
				url: '/auth/sign-in',
				method: 'POST',
				body: data,
			}),
		}),
		signOut: build.mutation<void, string>({
			query: (refreshToken) => ({
				url: '/auth/sign-out',
				method: 'POST',
				body: { refreshToken },
			}),
		}),
	}),
});

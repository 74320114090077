import { Col, Row } from 'antd';
import { Config } from 'config';
import { ItemColumnEnum } from 'modules/category/enums';
import { useCategoryItemsColumns } from 'modules/category/hooks';
import { useRebaseSoundtracksToCategoryMutation } from 'modules/category/requests/mutations';
import { Button, Select, Table } from 'modules/common/components';
import { FC, Key, useMemo, useState } from 'react';
import { DefaultOptionType } from 'antd/es/select';
import styles from './styles.module.css';
import { useGetSoundtracksQuery } from 'modules/soundtrack/requests/queries';
import { useLocalizedContent } from 'modules/common/providers';
import { SoundtrackErrorType } from 'modules/soundtrack/types/soundtrackError';
import { LanguageIdByName } from 'constants/languageIdByName';
import { SoundtrackType } from 'modules/soundtrack/types';
import { useTranslation } from 'react-i18next';
import { SoundtrackTableHeader } from 'modules/soundtrack/components';

type PropsType = {
	categoryId: number;
	refetch: () => Promise<void>;
	categorySoundtracks: SoundtrackType[];
};

export const AddItemsForm: FC<PropsType> = ({
	categoryId,
	refetch,
	categorySoundtracks,
}) => {
	const { t } = useTranslation('category', {
		keyPrefix: 'AddItemsForm',
	});
	const [selectedIds, setSelectedIds] = useState<Key[]>([]);
	const [addSoundtracks] = useRebaseSoundtracksToCategoryMutation();
	const [search, setSearch] = useState<string | undefined>();
	const [current] = useState(1);
	const [pageSize] = useState(100);
	const { locale } = useLocalizedContent();
	const [errorFilter, setErrorFilter] = useState<
		SoundtrackErrorType | undefined
	>();

	const columns = useCategoryItemsColumns({});

	const [categoryIdsFilter, setCategoryIdsFilter] = useState<
		number[] | undefined
	>();
	const [authorIdsFilter] = useState<number[] | undefined>();

	const { data: soundtrackList } = useGetSoundtracksQuery({
		errorFilter,
		current,
		pageSize,
		search,
		languageId: LanguageIdByName[locale],
		authorIdsFilter,
		categoryIdsFilter,
	});

	const [shownColumnKeys, setShownColumnKeys] = useState<ItemColumnEnum[]>(
		Config.DEFAULT_ITEMS_SHOWN_COLUMNS
	);

	const shownColumnOptions = useMemo<DefaultOptionType[]>(
		() =>
			columns
				.filter((column) => !!column.title)
				.map((column) => ({
					label: column.title?.toString(),
					value: column.key?.toString(),
				})),
		[columns]
	);

	const tableColumns = useMemo(
		() =>
			columns.filter((column) =>
				shownColumnKeys.some((key) => key === column.key)
			),
		[columns, shownColumnKeys]
	);

	const onColumnSelect = (selectedColumns: ItemColumnEnum[]) => {
		setShownColumnKeys(selectedColumns);
	};

	const onAddSelectedItems = async () => {
		if (!selectedIds) return;

		try {
			await addSoundtracks({
				pointedCategoryId: categoryId,
				itemIds: selectedIds,
			});
			setSelectedIds([]);

			refetch();
		} catch (e) {
			console.error(e);
		}
	};

	const displayItemsCount = useMemo(
		() => Config.MAX_SELECTED_SOUNDTRACKS_COUNT_DISPLAYED,
		[]
	);
	const isSubmitDisabled = useMemo(() => !selectedIds.length, [selectedIds]);
	const selectedSoundtracks = useMemo(
		() =>
			selectedIds.map((id) =>
				soundtrackList?.soundtracks.find((soundtrack) => soundtrack.id === id)
			),
		[selectedIds, soundtrackList]
	);

	return (
		<Row gutter={[0, 12]}>
			<Col span={24}>
				<h2>{t('addItems')}</h2>
			</Col>
			<Col span={24}>
				<Select
					value={shownColumnKeys}
					onChange={onColumnSelect}
					placeholder={t('shownColumns')}
					className={styles.selectColumns}
					options={shownColumnOptions}
					mode='multiple'
					maxTagCount='responsive'
				/>
			</Col>
			<Col span={24}></Col>
			<Col span={24}>
				{selectedSoundtracks
					.splice(0, displayItemsCount)
					.map((soundtrack) => `(id: ${soundtrack?.id})${soundtrack?.title}`)
					.join(', ')}
				{selectedIds.length > displayItemsCount &&
					` ... +${selectedIds.length - displayItemsCount} more -t`}
				<Button
					danger
					type='link'
					onClick={() => setSelectedIds([])}
					disabled={!selectedIds.length}
				>
					{t('clearSelected')}
				</Button>
			</Col>
			<Col span={24}>
				<SoundtrackTableHeader
					setCategoryIdsFilter={setCategoryIdsFilter}
					setSearch={setSearch}
					setErrorFilter={setErrorFilter}
					categoryIdsFilter={categoryIdsFilter}
				/>
			</Col>
			<Col span={24}>
				<Table
					scroll={{ x: 700, y: 500 }}
					rowKey='id'
					rowSelection={{
						selectedRowKeys: selectedIds,
						onChange: setSelectedIds,
						getCheckboxProps: (record) => ({
							disabled: categorySoundtracks.some(({ id }) => id === record.id),
							name: record.title,
						}),
					}}
					columns={[
						{
							key: 'sort',
							width: 40,
						},
						...tableColumns,
					]}
					dataSource={soundtrackList?.soundtracks || []}
					pagination={false}
					loading={false}
				/>
			</Col>
			<Col span={24}>
				<Button
					disabled={isSubmitDisabled}
					type='primary'
					onClick={onAddSelectedItems}
				>
					{t('submit')}
				</Button>
			</Col>
		</Row>
	);
};

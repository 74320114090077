import { ModuleType } from 'modules/common/types';
import { CategoryTreePage, HomeContent, UpcomingCategories } from './pages';
import { PageDefenseEnum, PathEnum } from 'modules/common/enums';
import { RoleEnum } from 'modules/auth/enums';

export const categoryModule: ModuleType = {
	pages: [
		{
			path: PathEnum.CATEGORIES,
			component: CategoryTreePage,
			pageDefense: PageDefenseEnum.AUTH,
			rolesAllowed: [RoleEnum.ADMIN],
		},
		{
			path: PathEnum.UPCOMING_CATEGORIES,
			component: UpcomingCategories,
			pageDefense: PageDefenseEnum.AUTH,
			rolesAllowed: [RoleEnum.ADMIN],
		},
		{
			path: PathEnum.HOME_CONTENT,
			component: HomeContent,
			pageDefense: PageDefenseEnum.AUTH,
			rolesAllowed: [RoleEnum.ADMIN],
		},
	],
};

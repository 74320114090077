import { Popover, Tag } from 'antd';
import { PresetColorType } from 'antd/es/_util/colors';
import { ColumnsType } from 'antd/es/table';
import { RoleEnum } from 'modules/auth/enums';
import { useGetUserQuery } from 'modules/auth/requests/queries';
import { Button } from 'modules/common/components';
import { UserType } from 'modules/user/types';
import { useTranslation } from 'react-i18next';

const tagColorByRole: { [key in RoleEnum]: PresetColorType } = {
	[RoleEnum.ADMIN]: 'red',
	[RoleEnum.USER]: 'green',
};

type PropsType = {
	deleteUser: (id: number) => Promise<void>;
};

export const useColumns = ({
	deleteUser,
}: PropsType): ColumnsType<UserType> => {
	const { t } = useTranslation('user', { keyPrefix: 'UsersTable' });
	const currentUser = useGetUserQuery();

	return [
		{
			fixed: 'left',
			title: t('id'),
			dataIndex: 'id',
			key: 'id',
			width: 100,
		},
		{
			title: t('firstName'),
			dataIndex: 'firstName',
			key: 'firstName',
		},
		{
			title: t('lastName'),
			dataIndex: 'lastName',
			key: 'lastName',
		},
		{
			title: t('phoneNumber'),
			dataIndex: 'phoneNumber',
			key: 'phoneNumber',
			render: (_, record) =>
				record.phoneNumber && record.countryCode
					? `${record.countryCode}${record.phoneNumber}`
					: '-',
		},
		{
			title: t('email'),
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: t('role'),
			dataIndex: 'role',
			key: 'role',
			render: (role: RoleEnum) => (
				<Tag color={tagColorByRole[role]}>{t(role)}</Tag>
			),
		},
		{
			title: t('actions'),
			key: 'actions',
			width: 100,
			fixed: 'right',
			render: (_, user) =>
				user.id !== currentUser.data?.id && (
					<>
						<Popover
							content={
								<Button type='link' danger onClick={() => deleteUser(user.id)}>
									{t('delete')}
								</Button>
							}
							trigger='click'
							title={t('deleteUserConfirmation')}
						>
							<Button type='link' danger>
								{t('delete')}
							</Button>
						</Popover>
					</>
				),
		},
	];
};

import { ModuleType } from 'modules/common/types';
import { SoundtrackList } from './pages/SoundtrackList';
import { EditSoundtrack } from './pages/EditSoundtrack';
import { CreateSoundtrack } from './pages/CreateSoundtrack';
import { PageDefenseEnum, PathEnum } from 'modules/common/enums';
import { RoleEnum } from 'modules/auth/enums';

export const soundtrackModule: ModuleType = {
	pages: [
		{
			path: PathEnum.SOUNDTRACKS,
			component: SoundtrackList,
			pageDefense: PageDefenseEnum.AUTH,
			rolesAllowed: [RoleEnum.ADMIN],
		},
		{
			path: PathEnum.SOUNDTRACK_EDIT,
			component: EditSoundtrack,
			pageDefense: PageDefenseEnum.AUTH,
			rolesAllowed: [RoleEnum.ADMIN],
		},
		{
			path: PathEnum.SOUNDTRACK_CREATE,
			component: CreateSoundtrack,
			pageDefense: PageDefenseEnum.AUTH,
			rolesAllowed: [RoleEnum.ADMIN],
		},
	],
};

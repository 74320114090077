import { TreeSelectProps } from 'antd';
import { FC } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import styles from './styles.module.css';
import { TreeSelect } from '../TreeSelect';

type ControlledTreeSelectProps = {
  useControllerProps: UseControllerProps<any>;
  treeSelectProps?: TreeSelectProps;
};

export const ControlledTreeSelect: FC<ControlledTreeSelectProps> = ({
	useControllerProps,
	treeSelectProps,
}) => {
	const {
		field: { onChange, onBlur, value },
	} = useController(useControllerProps);

	return (
		<TreeSelect
			className={styles.treeSelect}
			{...treeSelectProps}
			onBlur={onBlur}
			onChange={onChange}
			value={value}
		/>
	);
};

import { message } from 'antd';
import Upload, { RcFile } from 'antd/es/upload';
import { Config } from 'config';
import { useUploadAuthorImageMutation } from 'modules/author/requests/mutations';
import { Button } from 'modules/common/components';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type PropsType = {
	authorId: number;
	setImageCache: Dispatch<SetStateAction<Date>>;
};

export const UploadButton: FC<PropsType> = ({ authorId, setImageCache }) => {
	const [file, setFile] = useState<RcFile | undefined>();
	const [uploadImage, { isLoading }] = useUploadAuthorImageMutation();
	const { t } = useTranslation('author', {
		keyPrefix: 'UploadButton',
	});

	const validateImage = (file: RcFile) => {
		const isTypeValid = Config.AUTHOR_IMAGE_TYPES.includes(file.type);

		if (!isTypeValid) {
			const allowedTypes = Config.AUTHOR_IMAGE_TYPES.join(', ').replaceAll(
				'image/',
				''
			);
			message.warning(
				t('invalidFileType', {
					allowedTypes,
				})
			);
			setFile(undefined);
			return true;
		}

		const isSizeValid = file.size <= Config.AUTHOR_IMAGE_SIZE_BITES;

		if (!isSizeValid) {
			message.warning(
				t('invalidFileSize', {
					maxSize: Config.AUTHOR_IMAGE_SIZE_BITES / 1024 / 1024,
				})
			);
			setFile(undefined);
			return true;
		}

		setFile(file);

		return false;
	};

	useEffect(() => {
		if (!file) return;

		(async () => {
			await uploadImage({ file, authorId });
			setFile(undefined);
			setImageCache(new Date());
		})();
	}, [file, uploadImage, authorId]);

	return (
		<Upload
			beforeUpload={validateImage}
			maxCount={1}
			fileList={file ? [file] : []}
		>
			<Button loading={isLoading}>{t('uploadImage')}</Button>
		</Upload>
	);
};

import { LanguageEnum } from 'modules/common/enums';
import {
	CreateSoundtrackType,
	SoundtrackType,
	UpdateSoundtrackType,
	UploadSoundtrackMediaFileType,
} from 'modules/soundtrack/types';
import { UploadedSoundtrackInfoType } from 'modules/soundtrack/types/uploadedSoundtrackInfo';
import { hmApi } from 'store/api';

export const {
	useUpdateSoundtrackMutation,
	useCreateSoundtrackMutation,
	useUploadSoundtracksOnCDNMutation,
	useRemoveSoundtrackFilesMutation,
	useUploadSoundtrackOnCDNMutation,
	useUploadSoundtrackMediaFileMutation,
} = hmApi.injectEndpoints({
	endpoints: (builder) => ({
		updateSoundtrack: builder.mutation<void, UpdateSoundtrackType>({
			query: (body) => {
				return {
					url: 'soundtracks',
					method: 'PUT',
					body,
				};
			},
		}),
		createSoundtrack: builder.mutation<SoundtrackType, CreateSoundtrackType>({
			query: (body) => {
				return {
					url: 'soundtracks',
					method: 'POST',
					body,
				};
			},
		}),
		uploadSoundtracksOnCDN: builder.mutation<
			UploadedSoundtrackInfoType[],
			LanguageEnum
		>({
			query: (language) => ({
				url: `/soundtracks/files?language=${language}`,
				method: 'POST',
			}),
		}),
		uploadSoundtrackOnCDN: builder.mutation<UploadedSoundtrackInfoType, number>(
			{
				query: (id) => ({
					url: `/soundtracks/files/${id}`,
					method: 'POST',
				}),
			}
		),
		removeSoundtrackFiles: builder.mutation<void, void>({
			query: () => ({
				url: '/soundtracks/files',
				method: 'DELETE',
			}),
		}),
		uploadSoundtrackMediaFile: builder.mutation<
			void,
			UploadSoundtrackMediaFileType
		>({
			query: (body) => {
				const formData = new FormData();

				formData.append('file', body.file.originFileObj as Blob);
				formData.append('fileName', body.fileName);
				formData.append('folderName', body.folderName);
				formData.append('soundtrackId', body.soundtrackId.toString());

				return {
					url: '/soundtracks/medias',
					method: 'POST',
					body: formData,
				};
			},
		}),
	}),
});

import { ModuleType } from 'modules/common/types';
import { SignIn } from './pages';
import { PageDefenseEnum, PathEnum } from 'modules/common/enums';

export const authModule: ModuleType = {
	pages: [
		{
			path: PathEnum.SIGN_IN,
			component: SignIn,
			pageDefense: PageDefenseEnum.NO_AUTH,
		},
	],
};

import { SoundtrackType } from 'modules/soundtrack/types';
import { FC, Key, useEffect, useMemo, useState } from 'react';
import { useCategoryItemsColumns } from '../../hooks';
import { Table } from 'modules/common/components';
import { Row, Col, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { CategoryItemsHeader } from '../CategoryItemsHeader';
import {
	SortableContext,
	arrayMove,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { DraggableRow } from './draggableRow';
import { useUpdateCategoryItemsOrderMutation } from 'modules/category/requests/mutations';
import { ItemColumnEnum } from 'modules/category/enums';
import { EditCategoryItemModal } from '../EditCategoryItemModal';

type PropsType = {
	categoryId: number;
	soundtracks: SoundtrackType[];
	refetchAll: () => void;
	isCategoryItemsLoading: boolean;
};

export const CategoryItemsTable: FC<PropsType> = ({
	categoryId,
	soundtracks,
	refetchAll,
	isCategoryItemsLoading,
}) => {
	const [dataSource, setDataSource] = useState<SoundtrackType[]>([]);
	const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);
	const [updateCategoryItemsOrder] = useUpdateCategoryItemsOrderMutation();
	const { t } = useTranslation('category', {
		keyPrefix: 'CategoryItemsTable',
	});
	const [selectedSoundtrackId, setSelectedSoundtrackId] = useState<
		number | null
	>(null);
	const [isEditSoundtrackModalVisible, setIsEditSoundtrackModalVisible] =
		useState(false);

	const onEditCategoryItem = (id: number) => {
		setSelectedSoundtrackId(id);
		setIsEditSoundtrackModalVisible(true);
	};

	const columns = useCategoryItemsColumns({
		onEditCategoryItem,
	});

	const [shownColumnKeys, setShownColumnKeys] = useState<ItemColumnEnum[]>([]);

	const tableColumns = useMemo(
		() =>
			columns.filter((column) =>
				shownColumnKeys.some((key) => key === column.key)
			),
		[columns, shownColumnKeys]
	);

	useEffect(() => {
		setDataSource(soundtracks);
	}, [soundtracks]);

	const updateOrder = async (itemIds: number[]) => {
		try {
			await updateCategoryItemsOrder({ categoryId, itemIds });

			message.success(t('updateOrderSuccess'));
		} catch (e) {
			message.error(t('updateOrderError'));
		}
	};

	const onDragEnd = ({ active, over }: DragEndEvent) => {
		if (active.id !== over?.id) {
			const activeIndex = dataSource.findIndex(
				(soundtrack) => soundtrack?.id === active.id
			);
			const overIndex = dataSource.findIndex(
				(soundtrack) => soundtrack?.id === over?.id
			);
			const updatedList = arrayMove(dataSource, activeIndex, overIndex);
			const itemIds = updatedList.map((item) => item?.id);

			updateOrder(itemIds);
			setDataSource(updatedList);
		}
	};

	return (
		<Row gutter={[0, 24]}>
			<Col span={24}>
				<CategoryItemsHeader
					selectedKeys={selectedKeys}
					refetchAll={refetchAll}
					setSelectedKeys={setSelectedKeys}
					soundtracks={soundtracks}
					categoryId={categoryId}
					setShownColumnKeys={setShownColumnKeys}
					columns={columns}
					shownColumnKeys={shownColumnKeys}
				/>
			</Col>
			<Col span={24}>
				<DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
					<SortableContext
						items={dataSource.map((item) => item?.id)}
						strategy={verticalListSortingStrategy}
					>
						<Table
							components={{
								body: {
									row: DraggableRow,
								},
							}}
							scroll={{ x: 700, y: 500 }}
							rowSelection={{
								selectedRowKeys: selectedKeys,
								onChange: setSelectedKeys,
							}}
							rowKey='id'
							columns={[
								{
									key: 'sort',
									width: 40,
								},
								...tableColumns,
							]}
							dataSource={dataSource}
							pagination={false}
							loading={isCategoryItemsLoading}
						/>
					</SortableContext>
				</DndContext>
			</Col>
			{selectedSoundtrackId && (
				<EditCategoryItemModal
					key={selectedSoundtrackId}
					soundtrackId={selectedSoundtrackId}
					setIsOpenModal={setIsEditSoundtrackModalVisible}
					isOpenModal={isEditSoundtrackModalVisible}
					refetch={refetchAll}
				/>
			)}
		</Row>
	);
};

import { hmApi } from 'store/api';
import { create, remove, edit, getMany } from './endpoints';

export const endpoints = hmApi.injectEndpoints({
	endpoints: (builder) => ({
		getUserMessages: getMany(builder),
		createUserMessage: create(builder),
		editUserMessage: edit(builder),
		deleteUserMessage: remove(builder),
	}),
});

export const {
	useGetUserMessagesQuery,
	useCreateUserMessageMutation,
	useEditUserMessageMutation,
	useDeleteUserMessageMutation,
} = endpoints;

import { ControlledSelect } from 'modules/common/components';
import { LinkedFieldTypeEnum } from 'modules/common/enums';
import { FC } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type PropsType = {
	control: Control<any>;
	isError?: boolean;
};

export const LinkTypeField: FC<PropsType> = ({ control, isError }) => {
	const { t } = useTranslation('soundtrack', { keyPrefix: 'LinkTypeField' });
	const options = Object.values(LinkedFieldTypeEnum).map((value) => ({
		label: t(value),
		value,
	}));

	return (
		<ControlledSelect
			useControllerProps={{
				name: 'linkType',
				control,
			}}
			selectProps={{
				options,
				status: isError ? 'error' : undefined,
			}}
		/>
	);
};

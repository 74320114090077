import { Col, Row, message } from 'antd';
import { CategoryType } from 'modules/category/types';
import { FC } from 'react';
import { ValidationSchemaType, schema } from './schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useLocalizedContent } from 'modules/common/providers';
import { CategoryLibraryIdByLanguage } from 'constants/categoryLibraryByLanguage';
import {
	Button,
	CategoriesField,
	ControlledInput,
	ControlledNumberInput,
	ControlledSelect,
	ControlledSwitch,
} from 'modules/common/components';
import { useTranslation } from 'react-i18next';
import { useGetAllAuthorsQuery } from 'modules/author/requests/queries';
import styles from './styles.module.css';
import {
	useCreateCategoryMutation,
	useUpdateCategoryMutation,
} from 'modules/category/requests/mutations';
import { useGetCategoryByIdQuery } from 'modules/category/requests/queries';

type PropsType = {
	category?: Partial<CategoryType>;
	closeModal: () => void;
	defaultParentCategoryId?: number;
	refetch: () => Promise<void>;
};

export const CategoryForm: FC<PropsType> = ({
	category,
	closeModal,
	refetch,
	defaultParentCategoryId,
}) => {
	const { locale } = useLocalizedContent();
	const { t } = useTranslation('category', { keyPrefix: 'CategoryForm' });
	const { data: authors, isLoading: isAuthorsLoading } =
		useGetAllAuthorsQuery();
	const { data: parentCategory } = useGetCategoryByIdQuery(
		defaultParentCategoryId || CategoryLibraryIdByLanguage[locale]
	);

	const [createCategoryMutation, createCategoryQueryInfo] =
		useCreateCategoryMutation();
	const [updateCategoryMutation, updateCategoryInfo] =
		useUpdateCategoryMutation();

	const {
		control,
		handleSubmit,
		reset: resetModal,
		formState: { errors },
	} = useForm<ValidationSchemaType>({
		resolver: yupResolver(schema),
		values: {
			name: category?.name || '',
			isActive: category?.isActive,
			parentCategoryId:
				category?.parentCategoryId || CategoryLibraryIdByLanguage[locale],
			authorId: category?.authorId,
			subtitle: category?.subtitle,
			seriesId: category?.seriesId,
			isFrontendToggle: category ? category.isFrontendToggle : false,
		},
	});

	const createCategory = async (data: ValidationSchemaType) => {
		try {
			await createCategoryMutation({
				name: data.name,
				isActive: data.isActive,
				authorId: data.authorId,
				parentCategoryId: data.parentCategoryId,
				subtitle: data.subtitle,
				seriesId: data.seriesId,
				isFrontendToggle: data.isFrontendToggle,
			});

			refetch();
			resetModal();
			message.success(t('categoryCreated'));
			closeModal();
		} catch (e) {
			message.error(t('errorWhileCreatingCategory'));
		}
	};

	const updateCategory = async (data: ValidationSchemaType) => {
		if (!category?.id) return;
		try {
			await updateCategoryMutation({
				categoryId: category?.id,
				name: data.name,
				isActive: data.isActive,
				authorId: data.authorId,
				parentCategoryId: data.parentCategoryId,
				subtitle: data.subtitle,
				seriesId: data.seriesId,
				isFrontendToggle: data.isFrontendToggle,
			});

			refetch();
			resetModal();
			message.success(t('categoryUpdated'));
			closeModal();
		} catch (e) {
			message.error(t('errorWhileUpdatingCategory'));
		}
	};

	const onSubmit = handleSubmit(category?.id ? updateCategory : createCategory);

	return (
		<Row gutter={[0, 24]}>
			<Col span={24}>
				{t('name')}:
				<ControlledInput
					inputProps={{
						status: errors.name ? 'error' : undefined,
					}}
					useControllerProps={{
						control,
						name: 'name',
					}}
				/>
			</Col>
			<Col span={24}>
				{t('subtitle')}:
				<ControlledInput
					inputProps={{
						status: errors.subtitle ? 'error' : undefined,
					}}
					useControllerProps={{
						control,
						name: 'subtitle',
					}}
				/>
			</Col>
			<Col span={24}>
				{t('author')}:
				<ControlledSelect
					useControllerProps={{
						control,
						name: 'authorId',
					}}
					selectProps={{
						loading: isAuthorsLoading,
						options: authors?.map((author) => ({
							label: `${author.name.en} / ${author.name.he}`,
							value: author.id,
						})),
					}}
				/>
			</Col>
			<Col span={24}>
				{t('parentCategory')}:
				<CategoriesField
					control={control}
					name='parentCategoryId'
					categories={parentCategory ? [parentCategory] : []}
				/>
			</Col>
			<Col span={24}>
				{t('seriesId')}:
				<ControlledNumberInput
					inputProps={{
						status: errors.seriesId ? 'error' : undefined,
						type: 'number',
						className: styles.seriesIdInput,
						min: 1,
					}}
					useControllerProps={{
						control,
						name: 'seriesId',
					}}
				/>
			</Col>
			<Col span={24}>
				{t('frontendToggle')}:{' '}
				<ControlledSwitch
					useControllerProps={{
						control,
						name: 'isFrontendToggle',
						defaultValue: false,
					}}
				/>
			</Col>
			<Col span={24}>
				{t('active')}:{' '}
				<ControlledSwitch
					useControllerProps={{
						control,
						name: 'isActive',
						defaultValue: true,
					}}
				/>
			</Col>
			<Col span={24} className={styles.submitButtonContainer}>
				<Button
					loading={
						createCategoryQueryInfo.isLoading || updateCategoryInfo.isLoading
					}
					type='primary'
					onClick={onSubmit}
				>
					{t('submit')}
				</Button>
			</Col>
		</Row>
	);
};

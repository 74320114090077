import { TreeSelectProps } from 'antd';
import { CategoryLibraryIdByLanguage } from 'constants/categoryLibraryByLanguage';
import { useLazyGetCategoriesByParentIdsQuery } from 'modules/category/requests/queries';
import { categoryTreeServices } from 'modules/category/services';
import { CategoryTreeDataType, CategoryType } from 'modules/category/types';
import { ControlledTreeSelect } from 'modules/common/components';
import { useLocalizedContent } from 'modules/common/providers';
import { FC, useEffect, useState } from 'react';
import { Control } from 'react-hook-form';

type PropsType = {
	control: Control<any>;
	options?: CategoryTreeDataType[];
	categories?: CategoryType[];
	name?: string;
	multiple?: boolean;
	selectProps?: TreeSelectProps;
};

export const CategoriesField: FC<PropsType> = ({
	control,
	categories,
	name = 'categories',
	multiple = false,
	options,
	selectProps,
}) => {
	const [categoryTreeData, setCategoryTreeData] = useState<
		CategoryTreeDataType[]
	>([]);
	const [fetchCategories, fetchCategoriesInfo] =
		useLazyGetCategoriesByParentIdsQuery();
	const { locale } = useLocalizedContent();

	useEffect(() => {
		(async () => {
			const res = await fetchCategories({
				id: CategoryLibraryIdByLanguage[locale],
			});

			if (!res.data) return;
			const formattedCategories = res.data.map(
				categoryTreeServices.formateDataTreeSelect
			);
			const defaultCategories =
				categories?.map(categoryTreeServices.formateDataTreeSelect) || [];
			const cleanedCategories = defaultCategories.filter(
				(category) =>
					!formattedCategories.some((item) => item.value === category.value)
			);

			setCategoryTreeData([
				...(options?.length ? options : []),
				...cleanedCategories,
				...formattedCategories,
			]);
		})();
	}, [locale, options]);

	const onLoadTreeData: TreeSelectProps['loadData'] = async ({
		id,
	}): Promise<void> => {
		const res = await fetchCategories({ id });
		const categories = res.data || [];
		const formateCategories =
			categories.map(categoryTreeServices.formateDataTreeSelect) || [];

		const cleanedCategories = formateCategories.filter(
			(category) =>
				!categoryTreeData.some((item) => item.value === category.value)
		);
		setCategoryTreeData([...categoryTreeData, ...cleanedCategories]);
	};

	return (
		<ControlledTreeSelect
			useControllerProps={{
				name,
				control,
			}}
			treeSelectProps={{
				...selectProps,
				treeDefaultExpandedKeys: [CategoryLibraryIdByLanguage[locale]],
				treeDataSimpleMode: true,
				treeData: categoryTreeData,
				loadData: onLoadTreeData,
				multiple,
				loading: fetchCategoriesInfo.isFetching,
			}}
		/>
	);
};

import { Col, Modal, Popconfirm, Row, message } from 'antd';
import { Button } from 'modules/common/components';
import { FC, useEffect, useMemo, useState } from 'react';
import { UploadedSoundtracksTable } from '../UploadedSoundtracksTable';
import {
	useRemoveSoundtrackFilesMutation,
	useUploadSoundtracksOnCDNMutation,
} from 'modules/soundtrack/requests/mutations';
import { useGetSoundtrackUploadResultsQuery } from 'modules/soundtrack/requests/queries';
import { useLocalizedContent } from 'modules/common/providers';
import { useTranslation } from 'react-i18next';
import { UploadResultStatus } from 'modules/common/components/UploadResultStatus';
import { UploadStatusesEnum } from 'modules/common/enums';
import { Config } from 'config';

type PropsType = {
	refetchSoundtracks: () => void;
};

export const FilesManagementHeader: FC<PropsType> = ({
	refetchSoundtracks,
}) => {
	const { t } = useTranslation('soundtrack', {
		keyPrefix: 'FilesManagementHeader',
	});
	const { locale } = useLocalizedContent();
	const [resetSoundtracks, { isLoading: isResetLoading }] =
		useRemoveSoundtrackFilesMutation();
	const [generateJsons] = useUploadSoundtracksOnCDNMutation();
	const {
		data: uploadInfo,
		isLoading: isResultsLoading,
		refetch: refetchResults,
	} = useGetSoundtrackUploadResultsQuery(locale);
	const [isGenerateInfoModalShown, setIsGenerateInfoModalShown] =
		useState(false);

	const status = useMemo(
		() =>
			isResultsLoading
				? UploadStatusesEnum.PENDING
				: uploadInfo?.status || UploadStatusesEnum.ERROR,
		[isResultsLoading, uploadInfo]
	);

	useEffect(() => {
		if (uploadInfo?.status === UploadStatusesEnum.PENDING) {
			const interval = setInterval(() => {
				refetchResults();
			}, Config.REFETCH_UPLOAD_RESULTS_INTERVAL_MS);

			return () => clearInterval(interval);
		}

		// TODO add categories refetch after status === 'UPLOADED'
	}, [uploadInfo?.status]);

	const onShowGenerateInfoModal = () => {
		setIsGenerateInfoModalShown(true);
	};

	const onCloseGenerateInfoModal = () => {
		setIsGenerateInfoModalShown(false);
	};

	const onGenerateJsons = async () => {
		try {
			await generateJsons(locale);
			refetchResults();
		} catch (e) {
			return message.error(t('jsonsGenerationFailed'));
		}

		message.info(t('jsonsGenerated'));
	};

	const onResetSoundtracks = async () => {
		try {
			await resetSoundtracks();
			refetchSoundtracks();
			message.success(t('resetSuccess'));
		} catch (error) {
			message.error(t('resetError'));
		}
	};

	return (
		<Row gutter={[24, 0]} align='middle'>
			<Col>
				<Popconfirm
					title={t('resetSoundtracks')}
					description={t('resetSoundtracksDescription')}
					onConfirm={onResetSoundtracks}
					okText={t('yes')}
					cancelText={t('no')}
				>
					<Button danger loading={isResetLoading}>
						{t('reset')}
					</Button>
				</Popconfirm>
			</Col>
			<Col>
				<Button
					onClick={onGenerateJsons}
					loading={isResultsLoading}
					type='primary'
				>
					{t('generateJsons')}
				</Button>
			</Col>
			<Col>
				{t('resultStatus')}: <UploadResultStatus status={status} />
			</Col>
			{status === UploadStatusesEnum.UPLOADED && (
				<Col>
					<Button type='link' onClick={onShowGenerateInfoModal}>
						{t('showResults')}
					</Button>
				</Col>
			)}
			<Modal
				width={800}
				footer={false}
				open={isGenerateInfoModalShown}
				onCancel={onCloseGenerateInfoModal}
			>
				<UploadedSoundtracksTable soundtracksInfo={uploadInfo?.results || []} />
			</Modal>
		</Row>
	);
};

import { TopicType } from 'modules/topic/types';
import { hmApi } from 'store/api';

export const { useGetAllTopicsQuery } = hmApi.injectEndpoints({
	endpoints: (builder) => ({
		getAllTopics: builder.query<TopicType[], void>({
			query: () => ({
				url: 'topics/all',
				method: 'GET',
			}),
		}),
	}),
});

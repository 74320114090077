import { Row, Col, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { Button } from 'modules/common/components';
import { UserMessageType } from 'modules/user-messages/types';
import { useTranslation } from 'react-i18next';

const formateDate = (date: Date): string => {
	const timestamp = new Date(date);
	return dayjs(timestamp).format('MMM DD YYYY');
};

type PropsType = {
	onEditClick: (message: UserMessageType) => void;
	onDeleteRecord: (id: number) => void;
};

export const useColumns = ({
	onEditClick,
	onDeleteRecord,
}: PropsType): ColumnsType<UserMessageType> => {
	const { t } = useTranslation('userMessages', {
		keyPrefix: 'UserMessagesTable',
	});

	return [
		{
			key: 'id',
			dataIndex: 'id',
			title: t('id'),
			render: (_, rowData) => rowData.id,
		},
		{
			width: '400px',
			key: 'title',
			dataIndex: 'title',
			title: t('title'),
			render: (_, rowData) => rowData.title,
		},
		{
			width: '600px',
			key: 'message',
			dataIndex: 'message',
			title: t('message'),
			render: (_, rowData) => rowData.message,
		},
		{
			width: '150px',
			key: 'startDate',
			dataIndex: 'startDate',
			title: t('startDate'),
			render: (_, rowData) => formateDate(rowData.startDate),
		},
		{
			width: '150px',
			key: 'endDate',
			dataIndex: 'endDate',
			title: t('endDate'),
			render: (_, rowData) => formateDate(rowData.endDate),
		},
		{
			key: 'actions',
			title: t('actions'),
			render: (_, rowData) => (
				<Row gutter={[6, 6]}>
					<Col>
						<Button type='link' onClick={() => onEditClick(rowData)}>
							{t('edit')}
						</Button>
					</Col>
					<Col>
						<Popconfirm
							title={t('deleteConfirmation')}
							description={t('deleteDescription')}
							onConfirm={() => onDeleteRecord(rowData.id)}
							okText={t('yes')}
							cancelText={t('no')}
						>
							<Button danger type='link'>
								{t('delete')}
							</Button>
						</Popconfirm>
					</Col>
				</Row>
			),
		},
	];
};

import { InputProps, SelectProps } from 'antd';
import { FC } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import { PhoneNumberInput } from '../PhoneNumberInput';

type ControlledPhoneNumberInput = {
	useCountryCodeControllerProps: UseControllerProps<any>;
	usePhoneNumberCodeControllerProps: UseControllerProps<any>;
	inputProps: InputProps;
	selectProps: SelectProps;
};

export const ControlledPhoneNumberInput: FC<ControlledPhoneNumberInput> = ({
	useCountryCodeControllerProps,
	usePhoneNumberCodeControllerProps,
	selectProps,
	inputProps,
}) => {
	const { field: phoneNumberField } = useController(
		usePhoneNumberCodeControllerProps
	);

	const { field: countryCodeField } = useController(
		useCountryCodeControllerProps
	);

	return (
		<PhoneNumberInput
			inputProps={{
				...inputProps,
				onChange: phoneNumberField.onChange,
				onBlur: phoneNumberField.onBlur,
				value: phoneNumberField.value,
			}}
			selectProps={{
				...selectProps,
				onChange: countryCodeField.onChange,
				onBlur: countryCodeField.onBlur,
				value: countryCodeField.value,
			}}
		/>
	);
};

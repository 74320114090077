import { InputProps } from 'antd';
import { FC } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import { Input } from '../Input';

type ControlledInputProps = {
  useControllerProps: UseControllerProps<any>;
  inputProps?: InputProps;
};

export const ControlledInput: FC<ControlledInputProps> = ({
	useControllerProps,
	inputProps,
}) => {
	const {
		field: { onChange, onBlur, value },
	} = useController(useControllerProps);

	return (
		<Input {...inputProps} onBlur={onBlur} onChange={onChange} value={value} />
	);
};

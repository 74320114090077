import {
	Button,
	Col,
	Dropdown,
	Layout,
	Menu,
	MenuProps,
	Row,
	Space,
} from 'antd';
import { SiderMenuData } from 'constants/sdierMeny';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './styles.module.css';
import { DownOutlined } from '@ant-design/icons';
import { LanguageEnum } from 'modules/common/enums';
import { useLocalizedContent } from 'modules/common/providers';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';

const languageByLocale = {
	[LanguageEnum.ENGLISH]: 'English',
	[LanguageEnum.HEBREW]: 'Hebrew',
	[LanguageEnum.YIDDISH]: 'Yiddish',
};

const languageShortByLocale = {
	[LanguageEnum.ENGLISH]: 'EN',
	[LanguageEnum.HEBREW]: 'HE',
	[LanguageEnum.YIDDISH]: 'YI',
};

export const Sider: FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { t, i18n } = useTranslation('common', {
		keyPrefix: 'Sider',
	});
	const { locale, updateLocale } = useLocalizedContent();

	const onMenuItemClick: MenuProps['onClick'] = ({ key }) => {
		navigate(key);
	};

	const menuItems: MenuProps['items'] = [...(i18n.languages || [])]
		.sort()
		.map((lang: string) => ({
			key: lang,
			onClick: () => updateLocale(lang as LanguageEnum),
			label: languageByLocale[lang as LanguageEnum],
		}));

	return (
		<Layout.Sider
			width={270}
			theme='light'
			color='white'
			className={styles.sider}
		>
			<div className={styles.title}>{t('general')}</div>
			<Menu
				onClick={onMenuItemClick}
				selectedKeys={[location.pathname]}
				style={{ borderRight: 0 }} // not works if use it in css
				items={SiderMenuData.GENERAL.map((page): MenuItemType => {
					return { key: page.path, label: t(page.name) };
				})}
			/>
			<Row align='middle' wrap={false}>
				<Col>
					<div className={styles.title}>{t('languageDependent')}</div>
				</Col>
				<Col>
					<Dropdown
						menu={{
							items: menuItems,
							selectedKeys: [locale],
						}}
					>
						<Button type='text'>
							<Space>
								{languageShortByLocale[locale]}
								<DownOutlined />
							</Space>
						</Button>
					</Dropdown>
				</Col>
			</Row>
			<Menu
				onClick={onMenuItemClick}
				selectedKeys={[location.pathname]}
				style={{ borderRight: 0 }} // not works if use it in css
				items={SiderMenuData.LANGUAGE_DEPENDENT.map((page) => {
					return { key: page.path, label: t(page.name) };
					// return <Menu.Item key={page.path}>{t(page.name)} </Menu.Item>;
				})}
			/>
		</Layout.Sider>
	);
};

import { LanguageEnum } from 'modules/common/enums';
import {
	FC,
	PropsWithChildren,
	createContext,
	useContext,
	useState,
} from 'react';

const localeContext = createContext<{
	locale: LanguageEnum;
	updateLocale: (locale: LanguageEnum) => void;
		}>({
			locale: LanguageEnum.ENGLISH,
			updateLocale: () => 0,
		});

const storedLocale = localStorage.getItem('locale') as LanguageEnum;

export const LocalizedContentProvider: FC<PropsWithChildren> = ({
	children,
}) => {
	const [locale, setLocale] = useState<LanguageEnum>(
		storedLocale || LanguageEnum.ENGLISH
	);

	const updateLocale = (locale: LanguageEnum) => {
		localStorage.setItem('locale', locale);
		setLocale(locale);
	};

	const Provider = localeContext.Provider;

	return <Provider value={{ locale, updateLocale }}>{children}</Provider>;
};

export const useLocalizedContent = () => {
	const { locale, updateLocale } = useContext(localeContext);

	return {
		locale,
		updateLocale,
	};
};

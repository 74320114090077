import { message } from 'antd';
import {
	useCreateSoundtrackMutation,
	useUpdateSoundtrackMutation,
	useUploadSoundtrackOnCDNMutation,
	useUploadSoundtrackMediaFileMutation,
} from 'modules/soundtrack/requests/mutations';
import { useLazyGetSoundtrackQuery } from 'modules/soundtrack/requests/queries';
import { schema, ValidationSchemaType } from '../schema';
import { useLocalizedContent } from 'modules/common/providers';
import { SoundtrackType } from 'modules/soundtrack/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { LanguageIdByName } from 'constants/languageIdByName';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { LanguageEnum, UploadStatusesEnum } from 'modules/common/enums';
import { SoundtrackUploadStatusEnum } from 'modules/soundtrack/enums/soundtrackUploadStatus';
import { UploadedSoundtrackInfoType } from 'modules/soundtrack/types/uploadedSoundtrackInfo';
import { useTranslation } from 'react-i18next';
import { useGenerateCategoryListMutation } from 'modules/category/requests/mutations';
import { Config } from 'config';
import { mediaFileService } from 'modules/soundtrack/services';

type PropsType = {
	soundtrack?: Partial<SoundtrackType>;
	refetch?: () => void;
	onComplete?: () => void;
};

const getStatus = ({
	uploadSoundtrackOnCDNInfo,
	isLoading,
}: {
	uploadSoundtrackOnCDNInfo?: UploadedSoundtrackInfoType;
	isLoading: boolean;
}): UploadStatusesEnum => {
	if (isLoading) {
		return UploadStatusesEnum.PENDING;
	} else {
		const statuses = {
			[SoundtrackUploadStatusEnum.SUCCESS]: UploadStatusesEnum.UPLOADED,
			[SoundtrackUploadStatusEnum.ERROR]: UploadStatusesEnum.ERROR,
		};

		return statuses[
			uploadSoundtrackOnCDNInfo?.status || SoundtrackUploadStatusEnum.ERROR
		];
	}
};

const mapSoundtrackToValidationSchema = ({
	data,
	locale,
}: {
	data?: Partial<SoundtrackType>;
	locale: LanguageEnum;
}): ValidationSchemaType => ({
	categories: data?.categories?.map((category) => category.id),
	author: data?.authorId || 0,
	topics: data?.topics?.map((topic) => topic.id),
	title: data?.title || '',
	subtitle: data?.subtitle,
	linkType: data?.linkType || '',
	linkTarget: data?.linkTarget || '',
	type: data?.itemTypeId,
	metadata: data?.metadata,
	isAllowDownload: data ? data?.isAllowDownload || false : true,
	isDegraded: !!data?.isDegraded,
	forDistribution: !!data?.forDistribution,
	hasBookVersion: !!data?.hasBookVersion,
	languageId: data?.languageId || LanguageIdByName[locale],
});

export const useLogic = ({ soundtrack, refetch, onComplete }: PropsType) => {
	const { t } = useTranslation('soundtrack', { keyPrefix: 'SoundtrackForm' });

	const { locale } = useLocalizedContent();
	const [fileInfo, setFileInfo] = useState<null | {
		fileName: string;
		filePath: string;
		fileDuration: number;
	}>(null);

	const [generateCategoriesList, generateCategoriesListInfo] =
		useGenerateCategoryListMutation();
	const [updateSoundtrackMutation, updateSoundtrackInfo] =
		useUpdateSoundtrackMutation();
	const [createSoundtrackMutation, createSoundtrackInfo] =
		useCreateSoundtrackMutation();
	const [getSoundtrack] = useLazyGetSoundtrackQuery();
	const [uploadSoundtrackOnCDNMutation, uploadSoundtrackOnCDNInfo] =
		useUploadSoundtrackOnCDNMutation();
	const [uploadMedia, UploadMediaInfo] = useUploadSoundtrackMediaFileMutation();

	const status = useMemo(
		() =>
			getStatus({
				uploadSoundtrackOnCDNInfo: uploadSoundtrackOnCDNInfo.data,
				isLoading: uploadSoundtrackOnCDNInfo.isLoading,
			}),
		[uploadSoundtrackOnCDNInfo, uploadSoundtrackOnCDNInfo.isLoading]
	);
	const isEditing = useMemo(() => !!soundtrack?.id, [soundtrack]);

	const {
		control,
		handleSubmit,
		formState: { dirtyFields, errors },
		watch,
		setValue,
		getValues,
	} = useForm({
		resolver: yupResolver(schema),
		values: mapSoundtrackToValidationSchema({
			data: soundtrack,
			locale,
		}),
	});

	// standard isDirty is not working properly
	const isDirty = Object.keys(dirtyFields).length > 0;

	const [linkTarget, linkType] = watch(['linkTarget', 'linkType']);
	const file = watch('fileList')?.[0];

	useEffect(() => {
		const isMp3 = file?.type
			? !!Config.MEDIA_FORMATS.includes(file.type)
			: false;
		if (file && isMp3) {
			mediaFileService.getMediaFileDuration(file, (duration) => {
				setFileInfo({
					fileName: mediaFileService.getFileName(file.name),
					filePath: soundtrack?.id
						? mediaFileService.getFilePath(soundtrack?.id)
						: 'xx/future_soundtrack_id',
					fileDuration: duration,
				});
			});
		}
		setFileInfo(null);
	}, [file]);

	const createSoundtrack = async (data: ValidationSchemaType) => {
		try {
			const soundtrack = await createSoundtrackMutation({
				authorId: data.author,
				categoryIds: data.categories,
				title: data.title,
				subtitle: data.subtitle,
				topicIds: data.topics,
				itemTypeId: data.type,
				languageId: data.languageId,
				linkType: data.linkType || null,
				linkTarget: data.linkTarget || null,
				metadata: data.metadata,
				isAllowDownload: data.isAllowDownload,
				isDegraded: data.isDegraded,
				forDistribution: data.forDistribution,
				hasBookVersion: data.hasBookVersion,
				fileDuration: fileInfo?.fileDuration,
			}).unwrap();

			if (data.fileList?.[0]) {
				const file = data.fileList[0];
				const fileName = mediaFileService.getFileName(file.name);
				const folderName = mediaFileService.getFilePath(soundtrack.id);

				await uploadMedia({
					file,
					fileName,
					folderName,
					soundtrackId: soundtrack.id,
				});
			}

			refetch?.();
			onComplete?.();

			message.success(t('createdSuccess'));
		} catch (e) {
			message.error(t('createdError'));
		}
	};

	const updateSoundtrack = async (data: ValidationSchemaType) => {
		if (!soundtrack?.id) return;

		try {
			await updateSoundtrackMutation({
				id: soundtrack.id,
				authorId: data.author,
				categoryIds: data.categories,
				title: data.title,
				subtitle: data.subtitle,
				topicIds: data.topics,
				itemTypeId: data.type,
				languageId: data.languageId,
				linkType: data.linkType || null,
				linkTarget: data.linkTarget || null,
				metadata: data.metadata,
				isAllowDownload: data.isAllowDownload,
				isDegraded: data.isDegraded,
				forDistribution: data.forDistribution,
				hasBookVersion: data.hasBookVersion,
				fileDuration: fileInfo?.fileDuration,
			});

			if (fileInfo && data.fileList?.[0]) {
				await uploadMedia({
					file: data.fileList[0],
					fileName: fileInfo?.fileName,
					folderName: fileInfo?.filePath,
					soundtrackId: soundtrack.id,
				});
			}

			refetch?.();
			onComplete?.();

			message.success(t('updateSuccess'));
		} catch (e) {
			message.error(t('updateError'));
		}
	};

	const onSubmit = handleSubmit(
		isEditing ? updateSoundtrack : createSoundtrack
	);

	const onCopySoundtracks = async () => {
		try {
			const soundtrack = await getSoundtrack({
				id: Number(linkTarget),
			}).unwrap();

			const values: ValidationSchemaType = mapSoundtrackToValidationSchema({
				data: soundtrack,
				locale,
			});

			Object.entries(values).forEach(([key, value]) => {
				const fieldName = key as keyof ValidationSchemaType;
				if (fieldName !== 'linkTarget' && fieldName != 'linkType') {
					setValue(fieldName, value);
				}
			});
		} catch (e) {
			message.error(t('copyError'));
		}
	};

	const onGenerateJson = async () => {
		try {
			await uploadSoundtrackOnCDNMutation(soundtrack?.id || 0);
			message.success(t('generatedJson'));
		} catch (e) {
			console.error(e);
			message.error(t('generateJsonError'));
		}
	};

	const onGenerateCategories = async () => {
		try {
			const categoriesIds = soundtrack?.categories?.map(
				(category) => category.id
			);

			if (!categoriesIds) return;
			const results = await generateCategoriesList(categoriesIds).unwrap();

			results.forEach((result) => {
				if (result.error) {
					return message.error(
						t('categoryJsonFailed', {
							id: result.categoryId,
							error: result.error,
						}),
						6000
					);
				}
				message.success(
					t('categoryJsonGenerated', {
						id: result.categoryId,
					})
				);
			});

			refetch?.();
		} catch (e) {
			console.error(e);
			message.error(t('generateCategoriesJsonError'));
		}
	};

	const onRedirectToAudio = () => {
		if (soundtrack?.id) {
			const path = `${soundtrack.filePath}/${soundtrack.fileName}.mp3`.replace(
				/\/\//g,
				'/'
			);
			const url = `${process.env.REACT_APP_AUDIO_URL}/${path}`;
			window.open(url, '_blank');
		}
	};

	return {
		getValues,
		generateCategoriesListInfo,
		onCopySoundtracks,
		onGenerateJson,
		uploadSoundtrackOnCDNInfo,
		onSubmit,
		updateSoundtrackInfo,
		createSoundtrackInfo,
		status,
		control,
		errors,
		isDirty,
		linkType,
		linkTarget,
		onGenerateCategories,
		onRedirectToAudio,
		fileInfo,
		isLoading:
			updateSoundtrackInfo.isLoading ||
			createSoundtrackInfo.isLoading ||
			uploadSoundtrackOnCDNInfo.isLoading ||
			UploadMediaInfo.isLoading,
	};
};

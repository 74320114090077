import pino from 'pino';
import { logsSenderServices } from '../services/logsSender';

export const logger = pino({
	browser: {
		write: () => undefined,
		asObject: true,
		transmit: {
			send: (_level, logEvent) => {
				logsSenderServices.pushLog(logEvent);
			},
		},
	},
});

import { message, Card, Row, Col, Button } from 'antd';
import {
	useLazyGenerateHolidayCategoriesJsonQuery,
	useGetHolidayCategoriesQuery,
} from 'modules/category/requests/queries';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const HolidayCategories: FC = () => {
	const { t } = useTranslation('category', {
		keyPrefix: 'HolidayCategories',
	});

	const [generateJsonFile, generateJsonFileInfo] =
		useLazyGenerateHolidayCategoriesJsonQuery();
	const { data, isFetching } = useGetHolidayCategoriesQuery();

	const onGenerateJsonFile = async () => {
		try {
			await generateJsonFile();

			message.success(t('jsonGeneratedSuccessfully'));
		} catch (error) {
			message.error(t('errorWhileGeneratingJson'));
		}
	};

	return (
		<Card loading={isFetching}>
			<Row gutter={[0, 24]}>
				<Col span={24}>
					<h1>{t('holidayCategories')}</h1>
					<Button
						onClick={onGenerateJsonFile}
						loading={generateJsonFileInfo.isLoading}
						type='primary'
					>
						{t('generateJsons')}
					</Button>
				</Col>
				<Col span={12}>
					<Row>
						<Col span={24}>
							<h2>{t('english')}:</h2>
						</Col>
						{data?.en?.map((category) => (
							<Col span={24} key={category.id}>
								{category.name}{' '}
								{!!category.itemsCount && `(items: ${category.itemsCount})`}
								{!!category.childrenCount &&
									`(children: ${category.childrenCount})`}
							</Col>
						))}
					</Row>
				</Col>
				<Col span={12}>
					<Row>
						<Col span={24}>
							<h2>{t('yiddish')}:</h2>
						</Col>
						{data?.yi?.map((category) => (
							<Col span={24} key={category.id}>
								{category.name}{' '}
								{!!category.itemsCount && `(items: ${category.itemsCount})`}
								{!!category.childrenCount &&
									`(children: ${category.childrenCount})`}
							</Col>
						))}
					</Row>
				</Col>
			</Row>
		</Card>
	);
};

import { InputProps, SelectProps, Space } from 'antd';
import { FC } from 'react';
import { Select } from '../Select';
import countryCodes from 'assets/countryCodes.json';
import { DefaultOptionType } from 'antd/es/select';

import styles from './styles.module.css';
import { Input } from '..';

type PropsType = {
	inputProps: InputProps;
	selectProps: SelectProps;
};

type CountryCodeType = {
	name: string;
	code: string;
	emoji: string;
	unicode: string;
	image: string;
	dial_code: string;
};

const mapToOption = (country: CountryCodeType) => ({
	label: `${country.emoji} ${country.dial_code}`,
	value: `${country.code}-${country.dial_code}`,
});


const options: DefaultOptionType[] = [
	...countryCodes.map<DefaultOptionType>(mapToOption),
];

const defaultValue = options?.[0];

export const PhoneNumberInput: FC<PropsType> = ({
	inputProps,
	selectProps,
}) => {
	const { onChange, ...restInputProps } = inputProps;

	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		const phone = value.replace(/[^0-9]/g, '');
		onChange?.({...e, target: { ...e.target, value: phone }});
	};

	return (
		<Space.Compact>
			<Select
				optionFilterProp='label'
				showSearch
				className={styles.codeSelect}
				options={options}
				defaultValue={defaultValue}
				{...selectProps}
			/>
			<Input
				maxLength={15}
				className={styles.phoneNumberInput}
				onChange={onInputChange}
				{...restInputProps}
			/>
		</Space.Compact>
	);
};

import { Modal, Spin } from 'antd';
import { SoundtrackForm } from 'modules/soundtrack/components';
import { useGetSoundtrackQuery } from 'modules/soundtrack/requests/queries';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type PropsType = {
	soundtrackId: number;
	isOpenModal: boolean;
	setIsOpenModal: (isOpen: boolean) => void;
	refetch: () => void;
};

export const EditCategoryItemModal: FC<PropsType> = ({
	soundtrackId,
	isOpenModal,
	setIsOpenModal,
	refetch,
}) => {
	const { t } = useTranslation('category', {
		keyPrefix: 'EditCategoryItemModal',
	});
	const {
		data: soundtrack,
		isFetching,
		refetch: refetchSoundtrack,
	} = useGetSoundtrackQuery({
		id: soundtrackId,
	});

	const closeModal = () => {
		setIsOpenModal(false);
	};

	const refetchAll = () => {
		refetch();
		refetchSoundtrack();
	};

	return (
		<Modal width={700} footer={false} open={isOpenModal} onCancel={closeModal}>
			<Spin spinning={isFetching}>
				<h2>{t('editCategoryItem')}</h2>
				<SoundtrackForm
					refetch={refetchAll}
					soundtrack={soundtrack}
				/>
			</Spin>
		</Modal>
	);
};

import {
	CreateCategoryType,
	UpdateCategoryItemsType,
	UpdateCategoryOrderType,
	UpdateCategoryType,
	AddSoundtracksToCategoryType,
	UploadHomeContentCDNType,
	UpdateCategoryItemsOrderType,
	UploadedCategoryInfoType,
} from 'modules/category/types';
import { hmApi } from 'store/api';

export const {
	useUpdatedCategoryOrderMutation,
	useCreateCategoryMutation,
	useUpdateCategoryMutation,
	useUpdateHomeContentMutation,
	useUpdateCategoryItemsMutation,
	useRebaseSoundtracksToCategoryMutation,
	useUpdateCategoryItemsOrderMutation,
	useGenerateCategoryFilesMutation,
	useResetCategoriesMutation,
	useGenerateCategoryListMutation,
} = hmApi.injectEndpoints({
	endpoints: (builder) => ({
		updatedCategoryOrder: builder.mutation<undefined, UpdateCategoryOrderType>({
			query: (data) => ({
				url: 'categories/order',
				method: 'post',
				body: data,
			}),
		}),
		generateCategoryFiles: builder.mutation<{ message: string }, number>({
			query: (categoryId) => ({
				url: `categories/files/${categoryId}`,
				method: 'post',
			}),
		}),
		createCategory: builder.mutation<undefined, CreateCategoryType>({
			query: (data) => ({
				url: 'categories',
				method: 'post',
				body: data,
			}),
		}),
		updateCategory: builder.mutation<undefined, UpdateCategoryType>({
			query: (data) => ({
				url: 'categories',
				method: 'put',
				body: data,
			}),
		}),
		updateHomeContent: builder.mutation<undefined, UploadHomeContentCDNType>({
			query: (body) => ({
				url: 'home-content',
				method: 'post',
				body,
			}),
		}),
		updateCategoryItems: builder.mutation<undefined, UpdateCategoryItemsType>({
			query: (body) => ({
				url: `categories/${body.categoryId}/soundtracks`,
				method: 'put',
				body: {
					itemIds: body.itemIds,
				},
			}),
		}),
		rebaseSoundtracksToCategory: builder.mutation<
			undefined,
			AddSoundtracksToCategoryType
		>({
			query: (body) => ({
				url: `categories/${body.pointedCategoryId}/soundtracks`,
				method: 'post',
				body: {
					itemIds: body.itemIds,
					sourceCategoryId: body.sourceCategoryId,
				},
			}),
		}),
		updateCategoryItemsOrder: builder.mutation<
			undefined,
			UpdateCategoryItemsOrderType
		>({
			query: (body) => ({
				url: `categories/${body.categoryId}/soundtracks/order`,
				method: 'put',
				body: {
					itemIds: body.itemIds,
				},
			}),
		}),
		resetCategories: builder.mutation<void, void>({
			query: () => ({
				url: 'categories/files',
				method: 'delete',
			}),
		}),
		generateCategoryList: builder.mutation<
			UploadedCategoryInfoType[],
			number[]
		>({
			query: (categoryIds) => ({
				url: 'categories/files',
				method: 'post',
				body: { categoryIds },
			}),
		}),
	}),
});

import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { Row, Col, Button, Spin, Modal, message, Popconfirm } from 'antd';
import { UploadStatusesEnum } from 'modules/common/enums/uploadStatuses';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CategoryForm } from '../CategoryForm';
import { UploadedCategoryTable } from '../UploadedCategoryTable';
import {
	useGenerateCategoryFilesMutation,
	useResetCategoriesMutation,
} from 'modules/category/requests/mutations';
import { useGetCategoryUploadResultsQuery } from 'modules/category/requests/queries';
import { useLocalizedContent } from 'modules/common/providers';
import { Config } from 'config';
import { UploadResultStatus } from 'modules/common/components/UploadResultStatus';

type PropsType = {
	rootCategoryId: number;
	refetchTree: () => Promise<void>;
	selectedCategoryId?: number;
	setSelectedCategoryId: Dispatch<SetStateAction<number | undefined>>;
};

export const CategoryTreeHeader: FC<PropsType> = ({
	refetchTree,
	rootCategoryId,
	selectedCategoryId,
	setSelectedCategoryId,
}) => {
	const { locale } = useLocalizedContent();
	const { t } = useTranslation('category', { keyPrefix: 'CategoryTreeHeader' });
	const [generateJson, generateJsonInfo] = useGenerateCategoryFilesMutation();
	const [isCrateModalVisible, setIsCrateModalVisible] = useState(false);
	const [resetCategories, { isLoading: isResetLoading }] =
		useResetCategoriesMutation();
	const [isUploadResultModalVisible, setIsUploadResultModalVisible] =
		useState(false);
	const {
		data: uploadInfo,
		isLoading: isResultsLoading,
		refetch: refetchResults,
	} = useGetCategoryUploadResultsQuery(rootCategoryId);

	useEffect(() => {
		refetchResults();
	}, [locale]);

	useEffect(() => {
		if (uploadInfo?.status === UploadStatusesEnum.PENDING) {
			const interval = setInterval(() => {
				refetchResults();
			}, Config.REFETCH_UPLOAD_RESULTS_INTERVAL_MS);

			return () => clearInterval(interval);
		}

		if (uploadInfo?.status === UploadStatusesEnum.UPLOADED) {
			refetchTree();
		}
	}, [uploadInfo?.status]);

	const openModal = () => {
		setIsCrateModalVisible(true);
	};

	const closeCreateModal = () => {
		setIsCrateModalVisible(false);
	};

	const onOpenResultsModal = () => {
		setIsUploadResultModalVisible(true);
	};

	const onCloseResultsModal = () => {
		setIsUploadResultModalVisible(false);
	};

	const onGenerateJson = async () => {
		await generateJson(rootCategoryId);
		refetchTree();
		refetchResults();
		message.info('JSONs are generating');
	};

	const onRefetchClick = () => {
		refetchResults();
		refetchTree();
	};

	const onCloseSelectedCategory = () => {
		setSelectedCategoryId(undefined);
	};

	const status = isResultsLoading
		? UploadStatusesEnum.PENDING
		: uploadInfo?.status || UploadStatusesEnum.ERROR;

	const onResetCategories = async () => {
		try {
			await resetCategories();
			refetchTree();
			message.success(t('resetSuccess'));
		} catch (error) {
			message.error(t('resetError'));
		}
	};

	return (
		<Row gutter={[12, 12]} align='middle'>
			<Col>
				<Button
					type='link'
					icon={<ReloadOutlined />}
					onClick={onRefetchClick}
				/>
			</Col>
			<Col>
				<Popconfirm
					title={t('resetCategories')}
					description={t('resetCategoriesDescription')}
					onConfirm={onResetCategories}
					okText={t('yes')}
					cancelText={t('no')}
				>
					<Button danger loading={isResetLoading}>
						{t('reset')}
					</Button>
				</Popconfirm>
			</Col>
			<Col>
				<Button type='primary' icon={<PlusOutlined />} onClick={openModal}>
					{t('addCategory')}
				</Button>
			</Col>
			<Col>
				<Button loading={generateJsonInfo.isLoading} onClick={onGenerateJson}>
					{t('generateJsons')}
				</Button>
			</Col>
			{uploadInfo?.status === UploadStatusesEnum.UPLOADED && (
				<Col>
					<Button onClick={onOpenResultsModal}>{t('openResults')}</Button>
				</Col>
			)}
			<Col>
				{t('resultStatus')}: <UploadResultStatus status={status} />
			</Col>
			{selectedCategoryId && (
				<Col>
					<Button type='link' danger onClick={onCloseSelectedCategory}>
						{t('closeCategory')}
					</Button>
				</Col>
			)}
			<Modal
				footer={false}
				open={isCrateModalVisible}
				onCancel={closeCreateModal}
			>
				<h2>{t('addCategory')}</h2>
				<CategoryForm
					category={{
						parentCategoryId: selectedCategoryId,
					}}
					refetch={refetchTree}
					defaultParentCategoryId={selectedCategoryId}
					closeModal={closeCreateModal}
				/>
			</Modal>
			<Modal
				width={800}
				footer={false}
				open={isUploadResultModalVisible}
				onCancel={onCloseResultsModal}
			>
				<Spin spinning={isResultsLoading}>
					<UploadedCategoryTable results={uploadInfo?.results || []} />
				</Spin>
			</Modal>
		</Row>
	);
};

import { Card } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UserMessageTable } from 'modules/user-messages/components';

export const UserMessages: FC = () => {
	const { t } = useTranslation('userMessages', { keyPrefix: 'UserMessages' });

	return (
		<Card>
			<h2>{t('popups')}</h2>
			<UserMessageTable />
		</Card>
	);
};

import dayjs from 'dayjs';
import * as yup from 'yup';

export const schema = yup.object({
	title: yup.string(),
	message: yup.string().required('Message is required'),
	range: yup.mixed<[Date, Date]>().test({
		name: 'range',
		message: 'Start date must be before end date',
		test: (value) => {
			if (!value?.[0] || !value?.[1]) return false;

			return dayjs(value[0]).isBefore(value[1]) || dayjs(value[0]).isSame(value[1]);
		},
	}),
});

export type SchemaType = yup.InferType<typeof schema>;

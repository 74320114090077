import { UploadedCategoryInfoType } from 'modules/category/types/uploadedCategoryInfo';
import { Table } from 'modules/common/components';
import { FC, useState } from 'react';
import { useColumns } from './columns';
import { TableProps } from 'antd';
import { UploadedSoundtrackInfoType } from 'modules/soundtrack/types/uploadedSoundtrackInfo';
import { CategoryUploadStatusEnum } from 'modules/category/enums/categoryUploadStatus';
import { SoundtrackUploadStatusEnum } from 'modules/soundtrack/enums/soundtrackUploadStatus';

type PropTypes = {
	soundtracksInfo: UploadedSoundtrackInfoType[];
};

export const UploadedSoundtracksTable: FC<PropTypes> = ({
	soundtracksInfo,
}) => {
	const columns = useColumns();
	const [statusFilter, setStatusFilter] = useState<
		SoundtrackUploadStatusEnum[]
	>([]);

	const onChange: TableProps['onChange'] = (_pagination, filters) => {
		setStatusFilter((filters.status as SoundtrackUploadStatusEnum[]) || []);
	};

	const tableDataFilteredByStatus = soundtracksInfo.filter((category) =>
		statusFilter.length > 0 ? statusFilter.includes(category.status) : true
	);

	return (
		<Table
			onChange={onChange}
			scroll={{ x: 700, y: 500 }}
			rowKey={'soundtrackId'}
			columns={columns}
			dataSource={tableDataFilteredByStatus}
			expandable={{
				expandedRowRender: (record: UploadedCategoryInfoType) => (
					<p>{record.error}</p>
				),
				rowExpandable: (record: UploadedCategoryInfoType) =>
					record.status !== CategoryUploadStatusEnum.SUCCESS,
			}}
		/>
	);
};

import { configureStore } from '@reduxjs/toolkit';
import { hmApi } from './api';

export const store = configureStore({
	reducer: {
		[hmApi.reducerPath]: hmApi.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(hmApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

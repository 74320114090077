import { TableColumnsType } from 'antd';
import { ItemColumnEnum } from 'modules/category/enums';
import { Button } from 'modules/common/components';
import { SoundtrackTitle } from 'modules/soundtrack/components';
import { SoundtrackType } from 'modules/soundtrack/types';
import { useTranslation } from 'react-i18next';

type PropsType = {
	onEditCategoryItem?: (id: number) => void;
};

export const useCategoryItemsColumns = ({
	onEditCategoryItem,
}: PropsType): TableColumnsType<SoundtrackType> => {
	const { t } = useTranslation('category', {
		keyPrefix: 'CategoryItemsTable',
	});

	return [
		{
			width: 50,
			title: t('id'),
			dataIndex: ItemColumnEnum.ID,
			key: ItemColumnEnum.ID,
			render: (_, record) => {
				return record.id;
			},
		},
		{
			width: 150,
			title: t('title'),
			dataIndex: ItemColumnEnum.TITLE,
			key: ItemColumnEnum.TITLE,
			render: (_, record) => {
				return <SoundtrackTitle {...record} />;
			},
		},
		{
			width: 150,
			title: t('author'),
			dataIndex: ItemColumnEnum.AUTHOR,
			key: ItemColumnEnum.AUTHOR,
			render: (_, record) => {
				return `${record.author?.name.en} / ${record.author?.name.he}`;
			},
		},
		{
			width: 150,
			title: t('diburHamaschil'),
			dataIndex: ItemColumnEnum.DIBUR_HAMASCHIL,
			key: ItemColumnEnum.DIBUR_HAMASCHIL,
		},
		{
			width: 150,
			title: t('type'),
			dataIndex: ItemColumnEnum.TYPE,
			key: ItemColumnEnum.TYPE,
		},
		{
			width: 150,
			title: t('album'),
			dataIndex: ItemColumnEnum.ALBUM,
			key: ItemColumnEnum.ALBUM,
			render: (_, record) => {
				const album = record.main?.album || record.chitas?.album;
				return album ? `${album.nameEnglish} / ${album.nameHebrew}` : '';
			},
		},
		{
			width: 300,
			title: t('categories'),
			dataIndex: ItemColumnEnum.CATEGORIES,
			key: ItemColumnEnum.CATEGORIES,
			ellipsis: true,
			render: (_, record) => {
				return record.categories?.map((category) => category.name).join(', ');
			},
		},
		{
			width: 150,
			title: t('aboutPerson'),
			dataIndex: ItemColumnEnum.ABOUT_PERSON,
			key: ItemColumnEnum.ABOUT_PERSON,
			render: (_, record) => {
				return record.main?.aboutPerson;
			},
		},
		{
			width: 150,
			title: t('discNumber'),
			dataIndex: ItemColumnEnum.DISC_NUMBER,
			key: ItemColumnEnum.DISC_NUMBER,
			render: (_, record) => {
				return record.main?.discNumber || record.chitas?.discNumber;
			},
		},
		{
			width: 150,
			title: t('siman'),
			dataIndex: ItemColumnEnum.SIMAN,
			key: ItemColumnEnum.SIMAN,
			render: (_, record) => {
				return record.main?.siman || record.chitas?.siman;
			},
		},
		{
			width: 150,
			title: t('yearOfLecture'),
			dataIndex: ItemColumnEnum.YEAR_OF_LECTURE,
			key: ItemColumnEnum.YEAR_OF_LECTURE,
			render: (_, record) => {
				return record.main?.yearOfLecture || record.chitas?.yearOfLecture;
			},
		},
		{
			width: 150,
			title: t('monthOfLecture'),
			dataIndex: ItemColumnEnum.MONTH_OF_LECTURE,
			key: ItemColumnEnum.MONTH_OF_LECTURE,
			render: (_, record) => {
				return record.main?.monthOfLecture || record.chitas?.monthOfLecture;
			},
		},
		{
			width: 150,
			title: t('distribution'),
			dataIndex: ItemColumnEnum.DISTRIBUTION,
			key: ItemColumnEnum.DISTRIBUTION,
			render: (_, record) => {
				return record.main?.distribution;
			},
		},
		{
			width: 150,
			title: t('note'),
			dataIndex: ItemColumnEnum.NOTE,
			key: ItemColumnEnum.NOTE,
			render: (_, record) => {
				return record.main?.note || record.chitas?.note;
			},
		},
		{
			width: 150,
			title: t('dayLookup1'),
			dataIndex: ItemColumnEnum.DAY_LOOKUP1,
			key: ItemColumnEnum.DAY_LOOKUP1,
			render: (_, record) => {
				return record.main?.dayLookup1 || record.chitas?.dayLookup1;
			},
		},
		{
			width: 150,
			title: t('dayLookup2'),
			dataIndex: ItemColumnEnum.DAY_LOOKUP2,
			key: ItemColumnEnum.DAY_LOOKUP2,
			render: (_, record) => {
				return record.main?.dayLookup2 || record.chitas?.dayLookup2;
			},
		},
		{
			width: 100,
			title: t('actions'),
			key: ItemColumnEnum.VIEW,
			render: (_, record) => {
				const onEdit = () => {
					onEditCategoryItem?.(record.id);
				};

				return <Button onClick={onEdit}>{t('edit')}</Button>;
			},
			align: 'center',
		},
	];
};

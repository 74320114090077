import { yupResolver } from '@hookform/resolvers/yup';
import { Card, Col, Row, Tag, message } from 'antd';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { schema } from './schema';
import { Button, CategoriesField } from 'modules/common/components';
import { useLocalizedContent } from 'modules/common/providers';
import { useGetHomePageContentQuery } from 'modules/category/requests/queries';
import { useUpdateHomeContentMutation } from 'modules/category/requests/mutations';
import { useTranslation } from 'react-i18next';
import { useLazyUpdatedAppConfigQuery } from 'modules/appConfig/requests/queries';

import styles from './styles.module.css';

export const HomeContent: FC = () => {
	const { locale } = useLocalizedContent();
	const { t } = useTranslation('category', { keyPrefix: 'HomeContent' });

	const {
		data: homePageContent,
		isLoading,
		refetch,
		error,
	} = useGetHomePageContentQuery(locale);
	const [updateHomeContent, updateHomeContentInfo] =
		useUpdateHomeContentMutation();
	const [updatedAppConfig, { isLoading: isAppConfigLoading }] =
		useLazyUpdatedAppConfigQuery();

	const { control, handleSubmit, setValue } = useForm({
		resolver: yupResolver(schema),
		values: {
			suggestionIds: homePageContent?.suggestions.map((item) => item.id),
		},
	});

	useEffect(() => {
		if (error) {
			setValue('suggestionIds', []);
		}
	}, [error]);

	const onSubmit = handleSubmit(async (data) => {
		try {
			await updateHomeContent({
				lang: locale,
				...data,
			});
			message.success(t('updatedSuccessfully'));
			refetch();
		} catch (e) {
			message.error(t('errorWhileUpdating'));
		}
	});

	const onUpdateAppConfigCLick = async () => {
		try {
			await updatedAppConfig();
			message.success(t('appConfigUpdated'));
		} catch (e) {
			message.error(t('errorWhileUpdatingAppConfig'));
		}
	};

	return (
		<Card loading={isLoading}>
			<Row>
				<Col span={24}>
					<Row gutter={[24, 0]}>
						<Col>
							<h1>{t('homeContent')}</h1>
						</Col>
						<Col>
							<Button
								onClick={onSubmit}
								loading={updateHomeContentInfo.isLoading}
								type='primary'
							>
								{t('uploadChanges')}
							</Button>
						</Col>
						<Col>
							<Button
								onClick={onUpdateAppConfigCLick}
								loading={isAppConfigLoading}
							>
								{t('generateAppConfig')}
							</Button>
						</Col>
					</Row>
				</Col>
				<Col span={24}>
					<h3>{t('dailyStudies')}:</h3>
					<div className={styles.homeCategoryTagsWrapper}>
						{homePageContent?.dailyStudies.map((item) => (
							<Tag className={styles.homeCategoryTag} key={item.entityId}>
								{item.name}
							</Tag>
						))}
					</div>
				</Col>
				<Col span={24}>
					<h3>{t('otherContent')}:</h3>
					<CategoriesField
						categories={homePageContent?.suggestions}
						name='suggestionIds'
						control={control}
						multiple
					/>
				</Col>
			</Row>
		</Card>
	);
};

import { UploadFile } from 'antd';

export class MediaFileService {
	getFileName(fileNameWithFormat: string) {
		const splittedName = fileNameWithFormat.split('.');
		return splittedName.slice(0, -1).join('.');
	}

	getFilePath(soundtrackId: number) {
		const stringifiedId = soundtrackId.toString();
		if (stringifiedId.length < 2) {
			return `0${stringifiedId}/${stringifiedId}`;
		}

		return `${stringifiedId.slice(0, 2)}/${stringifiedId}`;
	}

	getMediaFileDuration(
		file: UploadFile<any>,
		callback?: (duration: number) => void
	): Promise<number> {
		return new Promise((resolve, reject) => {
			const audio = new Audio();
			audio.src = URL.createObjectURL(file.originFileObj as Blob);

			audio.addEventListener('loadedmetadata', () => {
				callback?.(Math.round(audio.duration));
				resolve(Math.round(audio.duration));
			});

			audio.addEventListener('error', (e) => {
				reject(e);
			});
		});
	}
}

export const mediaFileService = new MediaFileService();

import { SiderMenuData } from 'constants/sdierMeny';
import { UserType } from 'modules/user/types';
import { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

type PropsType = { user?: UserType } & PropsWithChildren;

export const NoAuth: FC<PropsType> = ({ children, user }) => {
	if (user) {
		return <Navigate to={SiderMenuData.DEFAULT_PATHS} />;
	}

	return <>{children}</>;
};

import { Col, Row, Image } from 'antd';
import { FC } from 'react';
import styles from './styles.module.css';

export const HeaderTitle: FC = () => {
	return (
		<Row align='middle' gutter={[24, 0]} wrap={false}>
			<Col>
				<Image preview={false} width={30} src='/images/hm_logo.png' />
			</Col>
			<Col className={styles.title}>Heichal Menachem</Col>
		</Row>
	);
};

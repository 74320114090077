import { PageType } from 'modules/common/types';
import { UserMessages } from './UserMessages';
import { PageDefenseEnum, PathEnum } from 'modules/common/enums';
import { RoleEnum } from 'modules/auth/enums';

export const pages: PageType[] = [
	{
		path: PathEnum.USER_MESSAGES,
		component: UserMessages,
		pageDefense: PageDefenseEnum.AUTH,
		rolesAllowed: [RoleEnum.ADMIN],
	},
];

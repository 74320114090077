import { LocalStorageKeysEnum } from 'modules/common/enums';

class TokensServices {
	async refreshTokens() {
		const refreshToken = this.getRefreshToken();

		const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/tokens`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ refreshToken }),
		}).then((res) => res.json());


		if (res.statusCode === 401) {
			throw new Error('Unauthorized');
		}

		this.addTokensToLocalStorage(res);
	}

	addTokensToLocalStorage(tokens: {
		accessToken: string;
		refreshToken: string;
	}) {
		localStorage.setItem(LocalStorageKeysEnum.ACCESS_TOKEN, tokens.accessToken);
		localStorage.setItem(
			LocalStorageKeysEnum.REFRESH_TOKEN,
			tokens.refreshToken
		);
	}

	removeTokens() {
		localStorage.removeItem(LocalStorageKeysEnum.ACCESS_TOKEN);
		localStorage.removeItem(LocalStorageKeysEnum.REFRESH_TOKEN);
	}

	getAccessToken() {
		return localStorage.getItem(LocalStorageKeysEnum.ACCESS_TOKEN);
	}
	getRefreshToken() {
		return localStorage.getItem(LocalStorageKeysEnum.REFRESH_TOKEN);
	}
}

export const tokensServices = new TokensServices();

import { CheckboxProps } from 'antd';
import { FC } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import { Checkbox } from '../Checkbox';

type ControlledCheckboxProps = {
  useControllerProps: UseControllerProps<any>;
  checkboxProps?: CheckboxProps;
};

export const ControlledCheckbox: FC<ControlledCheckboxProps> = ({
	useControllerProps,
	checkboxProps,
}) => {
	const {
		field: { onChange, value },
	} = useController(useControllerProps);

	return <Checkbox {...checkboxProps} onChange={onChange} checked={value} />;
};

import { Upload, UploadProps } from 'antd';
import { FC } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';

type PropsType = {
	useControllerProps: UseControllerProps<any>;
	fileUploadProps?: UploadProps;
	content?: JSX.Element;
};

export const ControlledFileUploadButton: FC<PropsType> = (props) => {
	const {
		field: { onChange, value },
	} = useController(props.useControllerProps);

	return (
		<Upload
			{...props.fileUploadProps}
			onChange={({ fileList, ...rest }) => {
				props.fileUploadProps?.onChange?.({ fileList, ...rest });
				onChange(fileList);
			}}
			fileList={value}
		>
			{props.content}
		</Upload>
	);
};

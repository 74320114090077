import 'antd/dist/reset.css';

import { App as AntdApp } from 'antd';

import { BrowserRouter } from 'react-router-dom';
import { ColorModeContextProvider } from './contexts/color-mode';

import { store } from './store';
import { Provider } from 'react-redux';

import { FC, Suspense } from 'react';
import { modules } from 'modules';
import { Pages } from 'modules/common/components';
import { LocalizedContentProvider } from 'modules/common/providers';

const pages = modules.map((module) => module.pages).flat();

const App: FC = () => {
	return (
		<Suspense fallback={<div>Loading...</div>}>
			<BrowserRouter>
				<Provider store={store}>
					<LocalizedContentProvider>
						<ColorModeContextProvider>
							<AntdApp>
								<Pages pages={pages} />
							</AntdApp>
						</ColorModeContextProvider>
					</LocalizedContentProvider>
				</Provider>
			</BrowserRouter>
		</Suspense>
	);
};

export default App;

import { UploadedCategoryInfoType } from 'modules/category/types/uploadedCategoryInfo';
import { Table } from 'modules/common/components';
import { FC, useState } from 'react';
import { useColumns } from './columns';
import { TableProps } from 'antd';
import { CategoryUploadStatusEnum } from 'modules/category/enums/categoryUploadStatus';

type PropTypes = {
	results: UploadedCategoryInfoType[];
};

export const UploadedCategoryTable: FC<PropTypes> = ({ results }) => {
	const columns = useColumns();
	const [statusFilter, setStatusFilter] = useState<CategoryUploadStatusEnum[]>(
		[]
	);

	const onChange: TableProps['onChange'] = (_pagination, filters) => {
		setStatusFilter((filters.status as CategoryUploadStatusEnum[]) || []);
	};

	const tableDataFilteredByStatus = results.filter((category) =>
		statusFilter.length > 0 ? statusFilter.includes(category.status) : true
	);

	return (
		<Table
			onChange={onChange}
			scroll={{ x: 700, y: 500 }}
			rowKey={'categoryId'}
			columns={columns}
			dataSource={tableDataFilteredByStatus}
			expandable={{
				expandedRowRender: (record: UploadedCategoryInfoType) => (
					<p>{record.error}</p>
				),
				rowExpandable: (record: UploadedCategoryInfoType) =>
					record.status !== CategoryUploadStatusEnum.SUCCESS,
			}}
		/>
	);
};

import { ControlledSelect } from 'modules/common/components';
import { useGetAllTopicsQuery } from 'modules/topic/requests/queries';
import { FC } from 'react';
import { Control } from 'react-hook-form';

type PropsType = {
  control: Control<any>;
};

export const TopicsField: FC<PropsType> = ({ control }) => {
	const { data: topics, isLoading: isTopicsLoading } = useGetAllTopicsQuery();

	return (
		<ControlledSelect
			useControllerProps={{
				name: 'topics',
				control,
			}}
			selectProps={{
				loading: isTopicsLoading,
				options: topics?.map((topic) => ({
					label: `${topic.topic.en} / ${topic.topic.he}`,
					value: topic.id,
				})),
				mode: 'multiple',
			}}
		/>
	);
};

import { hmApi } from 'store/api';

export const { useLazyUpdatedAppConfigQuery } = hmApi.injectEndpoints({
	endpoints: (build) => ({
		updatedAppConfig: build.query<undefined, void>({
			query: () => ({
				url: '/app-config',
				method: 'POST',
			}),
		}),
	}),
});

import { InputNumberProps } from 'antd';
import { FC } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import {} from '../Input';
import { InputNumber } from '../InputNumber';

type ControlledInputNumberProps = {
	useControllerProps: UseControllerProps<any>;
	inputProps?: InputNumberProps;
};

export const ControlledNumberInput: FC<ControlledInputNumberProps> = ({
	useControllerProps,
	inputProps,
}) => {
	const {
		field: { onChange, onBlur, value },
	} = useController(useControllerProps);

	return (
		<InputNumber
			{...inputProps}
			onBlur={onBlur}
			onChange={onChange}
			value={value}
		/>
	);
};

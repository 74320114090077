import { SwitchProps } from 'antd';
import { FC } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import { Switch } from '../Switch';

type ControlledSwitchProps = {
  useControllerProps: UseControllerProps<any>;
  switchProps?: SwitchProps;
};

export const ControlledSwitch: FC<ControlledSwitchProps> = ({
	useControllerProps,
	switchProps,
}) => {
	const {
		field: { onChange, value },
	} = useController(useControllerProps);

	return <Switch {...switchProps} onChange={onChange} value={value} />;
};

import { UserType } from 'modules/user/types';
import { hmApi } from 'store/api';

export const { useGetUserQuery } = hmApi.injectEndpoints({
	endpoints: (build) => ({
		getUser: build.query<UserType, void>({
			query: () => 'user',
		}),
	}),
});

import { authModule } from './auth';
import { appConfigModule } from './appConfig';
import { authorModule } from './author';
import { categoryModule } from './category';
import { commonModule } from './common';
import { ModuleType } from './common/types';
import { soundtrackModule } from './soundtrack';
import { userModule } from './user';
import { userMessagesModule } from './user-messages';

export const modules: ModuleType[] = [
	categoryModule,
	authorModule,
	soundtrackModule,
	commonModule,
	authModule,
	appConfigModule,
	userModule,
	userMessagesModule,
];

import { ControlledSelect } from 'modules/common/components';
import { useGetSoundtrackTypesQuery } from 'modules/soundtrack/requests/queries';
import { FC } from 'react';
import { Control } from 'react-hook-form';

type PropsType = {
	control: Control<any>;
};

export const SoundtrackTypesField: FC<PropsType> = ({ control }) => {
	const { data: soundtrackTypes, isLoading: isSoundtrackTypesLoading } =
		useGetSoundtrackTypesQuery();

	return (
		<ControlledSelect
			useControllerProps={{
				name: 'type',
				control,
			}}
			selectProps={{
				loading: isSoundtrackTypesLoading,
				options: soundtrackTypes?.map((type) => ({
					label: type.type,
					value: type.id,
				})),
			}}
		/>
	);
};

import { Row, Col, Button, message } from 'antd';
import { UserMessageType } from 'modules/user-messages/types';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { schema, SchemaType } from './schema';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	ControlledInput,
	ControlledRangePicker,
	ControlledTextArea,
} from 'modules/common/components';
import {
	useCreateUserMessageMutation,
	useEditUserMessageMutation,
} from 'modules/user-messages/api';

type PropsType = {
	userMessage?: Partial<UserMessageType>;
	closeModal: () => void;
	refetch: () => void;
};

export const UserMessageForm: FC<PropsType> = ({
	userMessage,
	closeModal,
	refetch,
}) => {
	const { t } = useTranslation('userMessages', {
		keyPrefix: 'UserMessagesForm',
	});

	const [createMessage, createInfo] = useCreateUserMessageMutation();
	const [editMessage, editInfo] = useEditUserMessageMutation();

	const {
		handleSubmit,
		control,
		formState: { errors },
		reset,
	} = useForm({
		resolver: yupResolver(schema),
		values: {
			title: userMessage?.title || '',
			message: userMessage?.message || '',
			range:
				userMessage?.startDate && userMessage?.endDate
					? [userMessage.startDate, userMessage.endDate]
					: undefined,
		},
	});

	useEffect(() => {
		if (createInfo.isSuccess || editInfo.isSuccess) {
			message.success(t('success'));
		}
	}, [createInfo.isSuccess, editInfo.isSuccess]);

	const create = async (data: SchemaType) => {
		if (!data.range?.[0] || !data.range?.[1]) return;

		await createMessage({
			title: data.title,
			message: data.message,
			startDate: data.range[0],
			endDate: data.range[1],
		});
	};
	const update = async (data: SchemaType) => {
		if (!userMessage?.id || !data.range?.[0] || !data.range?.[1]) return;

		await editMessage({
			id: userMessage?.id,
			title: data.title,
			message: data.message,
			startDate: data.range[0],
			endDate: data.range[1],
		});
	};

	const onSubmit = handleSubmit(async (data: SchemaType) => {
		await (userMessage?.id ? update(data) : create(data));

		closeModal();
		refetch();
		reset();
	});

	const isLoading = createInfo.isLoading || editInfo.isLoading;

	return (
		<Row gutter={[0, 12]}>
			{userMessage?.id && (
				<Col span={24}>
					{t('id')}: {userMessage?.id}
				</Col>
			)}
			<Col span={24}>
				{t('title')}:{' '}
				<ControlledInput
					useControllerProps={{
						control,
						name: 'title',
					}}
					inputProps={{
						status: errors.title ? 'error' : undefined,
					}}
				/>
			</Col>
			<Col span={24}>
				{t('message')}:{' '}
				<ControlledTextArea
					useControllerProps={{
						control,
						name: 'message',
					}}
					textAreaProps={{
						status: errors.title ? 'error' : undefined,
					}}
				/>
			</Col>
			<Col span={24}>
				{t('range')}:{' '}
				<ControlledRangePicker
					useControllerProps={{ control, name: 'range' }}
					pickerProps={{
						status: errors.range ? 'error' : undefined,
					}}
				/>
			</Col>
			<Col span={24}>
				<Button loading={isLoading} type='primary' onClick={onSubmit}>
					{t('submit')}
				</Button>
			</Col>
		</Row>
	);
};

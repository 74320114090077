import * as yup from 'yup';

export const schema = yup.object({
	name: yup.string().required(),
	subtitle: yup.string().nullable(),
	isActive: yup.boolean().nullable(),
	authorId: yup.number().nullable(),
	parentCategoryId: yup.number().required(),
	seriesId: yup.number().nullable(),
	isFrontendToggle: yup.boolean().nullable(),
});

export type ValidationSchemaType = yup.InferType<typeof schema>;

import { Tag } from 'antd';
import { PresetColorType } from 'antd/es/_util/colors';
import { ColumnsType } from 'antd/es/table';
import { ColumnFilterItem } from 'antd/es/table/interface';
import { SoundtrackUploadStatusEnum } from 'modules/soundtrack/enums/soundtrackUploadStatus';
import { UploadedSoundtrackInfoType } from 'modules/soundtrack/types/uploadedSoundtrackInfo';
import { useTranslation } from 'react-i18next';

const colorByStatus: { [key in SoundtrackUploadStatusEnum]: PresetColorType } =
	{
		[SoundtrackUploadStatusEnum.SUCCESS]: 'green',
		[SoundtrackUploadStatusEnum.ERROR]: 'red',
	};

export const useColumns = (): ColumnsType<UploadedSoundtrackInfoType> => {
	const { t } = useTranslation('soundtrack', {
		keyPrefix: 'UploadedSoundtrackTable',
	});

	const statusFilterOptions: ColumnFilterItem[] = Object.values(
		SoundtrackUploadStatusEnum
	).map((status) => ({
		text: t(status),
		value: status,
	}));

	return [
		{
			key: 'id',
			dataIndex: 'soundtrackId',
			title: t('soundtrackId'),
			render: (_, rowData) => rowData.soundtrackId,
		},
		{
			key: 'status',
			dataIndex: 'status',
			title: t('status'),
			filters: statusFilterOptions,
			render: (_, rowData) => (
				<Tag color={colorByStatus[rowData.status]}>{t(rowData.status)}</Tag>
			),
		},
		{
			width: 300,
			dataIndex: 'error',
			title: t('error'),
			ellipsis: true,
			render: (_, rowData) => rowData.error || '-',
		},
	];
};

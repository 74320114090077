import { ApiBuilderType } from 'modules/common/types';
import { EditUserMessageType } from 'modules/user-messages/types';

export const edit = (builder: ApiBuilderType) =>
	builder.mutation<void, EditUserMessageType>({
		query: (data) => ({
			url: `/user-messages/${data.id}`,
			method: 'PUT',
			body: {
				title: data.title,
				message: data.message,
				startDate: data.startDate,
				endDate: data.endDate,
			},
		}),
	});

import { Col, Row, Tag } from 'antd';
import { SoundtrackType } from 'modules/soundtrack/types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const SoundtrackTitle: FC<SoundtrackType> = ({
	title,
	linkType,
	forDistribution,
	linkTarget,
}) => {
	const { t } = useTranslation('soundtrack', { keyPrefix: 'SoundtrackTitle' });

	return (
		<Row gutter={[12, 6]}>
			<Col>{title || '-'}</Col>
			<Col>
				{linkType && <Tag color='blue'>{t(linkType, { linkTarget })}</Tag>}
			</Col>
			<Col>
				{!forDistribution && <Tag color='red'>{t('notForDistribution')}</Tag>}
			</Col>
		</Row>
	);
};

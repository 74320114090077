import { Button, Col, Divider, Row, Tooltip } from 'antd';
import { SoundtrackType } from 'modules/soundtrack/types';
import { FC, useEffect, useState } from 'react';
import {
	CategoriesField,
	ControlledFileUploadButton,
	ControlledInput,
	ControlledTextArea,
	UploadResultStatus,
} from 'modules/common/components';
import {
	AuthorsField,
	LinkTypeField,
	SoundtrackTypesField,
	TopicsField,
} from './components';
import { LinkedFieldTypeEnum, UploadStatusesEnum } from 'modules/common/enums';

import styles from './styles.module.css';
import { TogglesSection } from './components/TogglesSection';
import { useLogic } from './hooks/useLogic';
import { LanguageIdField } from './components/LanguageIdField';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Config } from 'config';

type PropsType = {
	soundtrack?: Partial<SoundtrackType>;
	refetch?: () => void;
	onComplete?: () => void;
};

export const SoundtrackForm: FC<PropsType> = ({
	soundtrack,
	refetch,
	onComplete,
}) => {
	const { t } = useTranslation('soundtrack', { keyPrefix: 'SoundtrackForm' });
	const [isCopyButtonVisible, setIsCopyButtonVisible] = useState(false);

	const {
		onCopySoundtracks,
		onGenerateJson,
		uploadSoundtrackOnCDNInfo,
		onSubmit,
		isLoading,
		status,
		control,
		errors,
		isDirty,
		linkType,
		linkTarget,
		onGenerateCategories,
		generateCategoriesListInfo,
		onRedirectToAudio,
		fileInfo,
	} = useLogic({ soundtrack, refetch, onComplete });

	useEffect(() => {
		if (linkType === LinkedFieldTypeEnum.SOUNDTRACK && linkTarget) {
			return setIsCopyButtonVisible(true);
		}
		setIsCopyButtonVisible(false);
	}, [linkTarget, linkType]);

	const path = soundtrack ? `${soundtrack.filePath}/${soundtrack.fileName}.mp3`.replace(/\/\//g, '/') : '';
	const audioLink = soundtrack
		? `${process.env.REACT_APP_AUDIO_URL}/${path}`
		: undefined;

	return (
		<Row gutter={[0, 24]}>
			<Divider orientation='left'>{t('soundtrackFields')}</Divider>
			{soundtrack?.id && (
				<Col span={24}>
					<Row align='middle' gutter={[12, 12]}>
						<Col>
							{t('id')}: {soundtrack.id}
						</Col>
						<Col>
							<Tooltip
								title={t('generateJsonTooltip')}
								open={!isDirty ? false : undefined}
							>
								<Button
									type='primary'
									disabled={isDirty}
									loading={uploadSoundtrackOnCDNInfo.isLoading}
									onClick={onGenerateJson}
								>
									{t('generateJson')}
								</Button>
							</Tooltip>
						</Col>
						<Col>
							<Tooltip
								title={t('generateJsonTooltip')}
								open={!isDirty ? false : undefined}
							>
								<Button
									disabled={isDirty}
									loading={generateCategoriesListInfo.isLoading}
									onClick={onGenerateCategories}
								>
									{t('generateCategoriesJson')}
								</Button>
							</Tooltip>
						</Col>
						<Col>
							{uploadSoundtrackOnCDNInfo.data?.status && (
								<Tooltip
									title={uploadSoundtrackOnCDNInfo.data.error}
									open={status === UploadStatusesEnum.ERROR ? undefined : false}
								>
									<UploadResultStatus status={status} />
								</Tooltip>
							)}
						</Col>
					</Row>
				</Col>
			)}
			<Col span={24}>
				{t('language')}:
				<LanguageIdField control={control} error={errors.languageId} />
			</Col>
			<Col span={24}>
				{t('title')}:
				<ControlledInput
					useControllerProps={{
						name: 'title',
						control,
					}}
					inputProps={{
						status: errors.title ? 'error' : undefined,
					}}
				/>
			</Col>
			<Col span={24}>
				{t('subtitle')}:
				<ControlledTextArea
					useControllerProps={{
						name: 'subtitle',
						control,
					}}
				/>
			</Col>
			<Col span={24}>
				{t('categories')}:
				<CategoriesField
					control={control}
					categories={soundtrack?.categories}
					multiple
				/>
			</Col>
			<Col span={24}>
				{t('topics')}:
				<TopicsField control={control} />
			</Col>
			<Col span={24}>
				{t('type')}:
				<SoundtrackTypesField control={control} />
			</Col>
			<Col span={24}>
				{t('author')}:
				<AuthorsField isError={!!errors.author} control={control} />
			</Col>
			<Col span={24}>
				{t('linkType')}:
				<LinkTypeField control={control} isError={!!errors.linkType} />
			</Col>
			<Col span={24}>
				{t('linkTarget')}:{' '}
				{isCopyButtonVisible && (
					<Button
						onClick={onCopySoundtracks}
						type='link'
						className={styles.copyButton}
					>
						{t('insertFields')}
					</Button>
				)}
				<ControlledInput
					useControllerProps={{
						name: 'linkTarget',
						control,
					}}
					inputProps={{
						status: errors.linkTarget ? 'error' : undefined,
					}}
				/>
			</Col>
			<Col span={24}>
				{t('metadata')}:
				<ControlledTextArea
					useControllerProps={{
						name: 'metadata',
						control,
					}}
				/>
			</Col>
			<Divider orientation='left'>{t('currentFile')}</Divider>
			{soundtrack?.filePath && soundtrack?.fileName && (
				<Col span={24}>
					{t('currentLocation')}:{' '}
					<Button type='link' onClick={onRedirectToAudio}>
						{`${soundtrack?.filePath}/${soundtrack?.fileName}.mp3`.replace(
							/\/\//g,
							'/'
						)}
					</Button>
				</Col>
			)}
			{soundtrack?.fileName && (
				<Col span={24}>
					<audio controls>
						<source src={audioLink || undefined} type='audio/mpeg' />
						Your browser does not support the audio element.
					</audio>
				</Col>
			)}
			<Divider orientation='left'>{t('newFile')}</Divider>
			<Col span={24}>
				{t('fileName')}: {fileInfo?.fileName || '-'}
			</Col>
			<Col span={24}>
				{t('filePath')}: {fileInfo?.filePath || '-'}
			</Col>
			<Col span={24}>
				{t('fileDuration')}:{' '}
				{fileInfo?.fileDuration ? `${fileInfo.fileDuration} sec.` : '-'}
			</Col>
			<Col span={24}>
				{t('audioFile')}:
				<ControlledFileUploadButton
					useControllerProps={{
						control,
						name: 'fileList',
					}}
					fileUploadProps={{
						listType: 'picture-card',
						beforeUpload: (file) => !Config.MEDIA_FORMATS.includes(file.type),
						maxCount: 1,
					}}
					content={<Button type='link' icon={<UploadOutlined />} />}
				/>
			</Col>
			<Divider />
			<Col span={24}>
				<TogglesSection control={control} />
			</Col>
			<Col span={24}>
				<Button type='primary' loading={isLoading} onClick={onSubmit}>
					{t('submit')}
				</Button>
			</Col>
		</Row>
	);
};

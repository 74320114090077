import { Row, Col } from 'antd';
import {
	Button,
	ControlledInput,
	ControlledPhoneNumberInput,
	ControlledSelect,
} from 'modules/common/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import { useForm } from 'react-hook-form';
import { ValidationSchemaType, schema } from './schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAddUserMutation } from 'modules/user/requests/mutations';
import { DefaultOptionType } from 'antd/es/select';
import { RoleEnum } from 'modules/auth/enums';

type PropsType = {
	refreshUserList: () => void;
	closeModal: () => void;
};

const mapCountryCode = (countryCode: string) => countryCode.split('-')[1];

export const UserForm: FC<PropsType> = ({ refreshUserList, closeModal }) => {
	const { t } = useTranslation('user', { keyPrefix: 'UserForm' });
	const [addUserMutation, addAdminMutationInfo] = useAddUserMutation();
	const {
		control,
		formState: { errors },
		handleSubmit,
	} = useForm<ValidationSchemaType>({
		resolver: yupResolver(schema),
	});
	const selectOptions: DefaultOptionType[] = Object.values(RoleEnum).map(
		(role) => ({
			label: t(role),
			value: role,
		})
	);

	const addUser = async (userData: ValidationSchemaType) => {
		const isPhoneNumber = !!userData.countryCode && !!userData.phoneNumber;

		const countryCode = isPhoneNumber
			? mapCountryCode(userData.countryCode as string)
			: undefined;

		const phoneNumber = isPhoneNumber
			? (userData.phoneNumber as string)
			: undefined;

		await addUserMutation({
			...userData,
			phoneNumber,
			countryCode,
		});

		refreshUserList();
		closeModal();
	};

	const onSubmit = handleSubmit(addUser);

	return (
		<Row gutter={[0, 24]}>
			<Col span={24}>
				{t('role')}:
				<ControlledSelect
					selectProps={{
						status: errors.role ? 'error' : undefined,
						options: selectOptions,
					}}
					useControllerProps={{
						name: 'role',
						control,
					}}
				/>
			</Col>
			<Col span={24}>
				{t('firstName')}:
				<ControlledInput
					inputProps={{
						status: errors.firstName ? 'error' : undefined,
						maxLength: 255,
						showCount: true,
					}}
					useControllerProps={{
						control,
						name: 'firstName',
					}}
				/>
			</Col>
			<Col span={24}>
				{t('lastName')}:
				<ControlledInput
					inputProps={{
						status: errors.lastName ? 'error' : undefined,
						maxLength: 255,
						showCount: true,
					}}
					useControllerProps={{
						control,
						name: 'lastName',
					}}
				/>
			</Col>
			<Col span={24}>
				{t('email')}:
				<ControlledInput
					inputProps={{
						status: errors.email ? 'error' : undefined,
					}}
					useControllerProps={{
						control,
						name: 'email',
					}}
				/>
			</Col>
			<Col span={24}>
				<Row>
					<Col span={24}> {t('phoneNumber')}:</Col>
					<Col span={24}>
						<ControlledPhoneNumberInput
							useCountryCodeControllerProps={{
								control,
								name: 'countryCode',
							}}
							usePhoneNumberCodeControllerProps={{
								control,
								name: 'phoneNumber',
							}}
							inputProps={{
								status: errors.phoneNumber ? 'error' : undefined,
							}}
							selectProps={{
								status: errors.countryCode ? 'error' : undefined,
							}}
						/>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				{t('password')}:
				<ControlledInput
					inputProps={{
						type: 'password',
						status: errors.password ? 'error' : undefined,
					}}
					useControllerProps={{
						control,
						name: 'password',
					}}
				/>
			</Col>
			<Col span={24}>
				{t('repeatPassword')}:
				<ControlledInput
					inputProps={{
						type: 'password',
						status: errors.repeatPassword ? 'error' : undefined,
					}}
					useControllerProps={{
						control,
						name: 'repeatPassword',
					}}
				/>
			</Col>
			<Col className={styles.submitButtonContainer} span={24}>
				<Button
					type='primary'
					onClick={onSubmit}
					loading={addAdminMutationInfo.isLoading}
				>
					{t('submit')}
				</Button>
			</Col>
		</Row>
	);
};

import { Badge, Col, Row, Tag } from 'antd';
import { CategoryType } from 'modules/category/types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type PropsType = {
	category: CategoryType;
};

export const TreeItem: FC<PropsType> = ({ category }) => {
	const { t } = useTranslation('category', { keyPrefix: 'TreeItem' });

	return (
		<Row gutter={[12, 0]}>
			<Col>
				<Badge
					status={category.isDirty ? 'warning' : 'success'}
					text={`(${
						category.childrenCount || `${category.itemsCount} items`
					}) - (id:
  				${category.id}${
			category.seriesId ? `, seriesId: ${category.seriesId}` : ''
		}) ${category.name || '-'}`}
				/>
			</Col>
			<Col>
				{category.isActive ? (
					<Tag color='green'>{t('active')}</Tag>
				) : (
					<Tag color='red'>{t('inactive')}</Tag>
				)}
			</Col>
			{category.categoryPath && (
				<Col>
					<Tag color='red'>{t('noPath')}</Tag>
				</Col>
			)}
		</Row>
	);
};

import {
	FC,
	PropsWithChildren,
	createContext,
	useContext,
	useState,
} from 'react';
import { SoundtrackActionInfoType } from '../types';

type ContextType = {
  soundtracksActionInfo?: SoundtrackActionInfoType;
  setSoundtracksActionInfo: (info?: SoundtrackActionInfoType) => void;
};

const SoundtracksContext = createContext<ContextType | null>(null);

export const SoundtracksProvider: FC<PropsWithChildren> = ({ children }) => {
	const [soundtracksActionInfo, setSoundtracksActionInfo] =
    useState<SoundtrackActionInfoType>();

	return (
		<SoundtracksContext.Provider
			value={{
				soundtracksActionInfo,
				setSoundtracksActionInfo,
			}}
		>
			{children}
		</SoundtracksContext.Provider>
	);
};

export const useSoundtracksAction = () => {
	const context = useContext(SoundtracksContext);

	if (!context) {
		throw new Error('useSoundtrackAction must be used within a ItemsProvider');
	}

	return context;
};

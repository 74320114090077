import { Table } from 'modules/common/components';
import { FC } from 'react';
import { UserType } from '../../types';
import { useColumns } from './columns';
import { useDeleteUserMutation } from 'modules/user/requests/mutations';

type PropsType = {
	users: UserType[];
	page: number;
	pageSize: number;
	total: number;
	onPageChange: (page: number) => void;
	onPageSizeChange: (limit: number) => void;
	isUserListLoading: boolean;
	refetchList: () => void;
};

export const UsersTable: FC<PropsType> = ({
	isUserListLoading,
	users,
	pageSize,
	total,
	onPageChange,
	onPageSizeChange,
	page,
	refetchList,
}) => {
	const [deleteUserMutation, deleteUserInfo] = useDeleteUserMutation();

	const deleteUser = async (id: number) => {
		await deleteUserMutation(id);
		refetchList();
	};

	const columns = useColumns({ deleteUser });
	const isLoading = isUserListLoading || deleteUserInfo.isLoading;

	return (
		<Table
			scroll={{ x: 1300 }}
			pagination={{
				pageSize,
				current: page,
				total,
				onChange: onPageChange,
				onShowSizeChange: (_current, size) => onPageSizeChange(size),
				showSizeChanger: true,
			}}
			columns={columns}
			dataSource={users}
			loading={isLoading}
			rowKey={'id'}
		/>
	);
};

import { Card, Image } from 'antd';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

export const AuthorEdit: FC = () => {
	const { id } = useParams();

	return (
		<Card>
			<h1>Author {id}</h1>
			<Image
				width={200}
				src={`${process.env.REACT_APP_IMAGES_URL}/authors/${id}`}
			/>
		</Card>
	);
};

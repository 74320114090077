import { LocalStorageKeysEnum } from 'modules/common/enums';
import { ItemColumnEnum } from '../enums';

class ItemColumnsServices {
	addColumnsConfig(columnsConfig: ItemColumnEnum[]) {
		localStorage.setItem(
			LocalStorageKeysEnum.CATEGORY_ITEMS_SHOWN_COLUMNS,
			JSON.stringify(columnsConfig)
		);
	}

	getColumnsConfig(): ItemColumnEnum[] | null {
		const columnsConfig = localStorage.getItem(
			LocalStorageKeysEnum.CATEGORY_ITEMS_SHOWN_COLUMNS
		);

		return columnsConfig ? JSON.parse(columnsConfig) : null;
	}
}

export const itemColumnsServices = new ItemColumnsServices();

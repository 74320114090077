import { ColumnsType } from 'antd/es/table';
import { ColumnFilterItem } from 'antd/es/table/interface';
import { useGetAllAuthorsQuery } from 'modules/author/requests/queries';
import { AuthorType } from 'modules/author/types';
import { SoundtrackTitle } from 'modules/soundtrack/components';
import { SoundtrackType } from 'modules/soundtrack/types';
import { SoundtrackTypeType } from 'modules/soundtrack/types/soundtrackType';
import { useTranslation } from 'react-i18next';

export const useColumns = (): ColumnsType<SoundtrackType> => {
	const { t } = useTranslation('soundtrack', { keyPrefix: 'SoundtrackList' });
	const { data: authors } = useGetAllAuthorsQuery();

	const authorFilters = authors?.map<ColumnFilterItem>((author) => ({
		text: author.name.en,
		value: author.id,
	}));

	return [
		{
			title: t('id'),
			dataIndex: 'id',
		},
		{
			title: t('title'),
			dataIndex: 'title',
			render: (_, record) => <SoundtrackTitle {...record} />,
		},
		{
			title: t('subtitle'),
			dataIndex: 'subtitle',
			render: (_, record) => `${record?.subtitle || '-'}`,
		},
		{
			title: t('author'),
			dataIndex: 'author',
			filters: authorFilters,
			render: (author: AuthorType) => author?.name.en,
		},
		{
			title: t('dibur_hamaschil_heb'),
			dataIndex: 'dibur_hamaschil_heb',
		},
		{
			title: t('type'),
			dataIndex: 'type',
			render: (type: SoundtrackTypeType) => type.type,
		},
		{
			title: t('isDirty'),
			dataIndex: 'isDirty',
			render: (isDirty: boolean) => (isDirty ? t('yes') : t('no')),
		},
	];
};

import {
	AuthorType,
	CreateAuthorType,
	AuthorsUploadInfoType,
	UploadImageType,
} from 'modules/author/types';
import { FileType } from 'modules/common/types';
import { hmApi } from 'store/api';

export const {
	useUploadAuthorImageMutation,
	useCreateAuthorMutation,
	useGenerateAuthorJsonsMutation,
} = hmApi.injectEndpoints({
	endpoints: (builder) => ({
		uploadAuthorImage: builder.mutation<undefined, UploadImageType>({
			query: ({ authorId, file }) => {
				const formData = new FormData();
				formData.append('authorId', authorId.toString());
				formData.append('file', file as FileType);
				return {
					url: 'authors/image',
					method: 'POST',
					body: formData,
				};
			},
		}),
		generateAuthorJsons: builder.mutation<AuthorsUploadInfoType, void>({
			query: (options) => ({
				url: 'authors/file',
				method: 'POST',
				body: options,
			}),
		}),
		createAuthor: builder.mutation<AuthorType, CreateAuthorType>({
			query: (body) => {
				const formData = new FormData();

				formData.append('file', body.file?.originFileObj as FileType);
				formData.append('nameEng', body.nameEng || '');
				formData.append('nameHeb', body.nameHeb || '');
				formData.append('isNassi', body.isNassi ? 'true' : 'false');

				return {
					url: 'authors',
					method: 'POST',
					body: formData,
				};
			},
		}),
	}),
});

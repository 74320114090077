import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import { FC } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

const { RangePicker } = DatePicker;

type PropsType = {
	useControllerProps: UseControllerProps<any>;
	pickerProps?: RangePickerProps;
};

export const ControlledRangePicker: FC<PropsType> = ({
	useControllerProps,
	pickerProps,
}) => {
	const {
		field: { value, onChange },
	} = useController(useControllerProps);

	const normalizedValue: [Dayjs, Dayjs] | null =
		!!value?.[0] && !!value?.[1] ? [dayjs(value[0]), dayjs(value[1])] : null;

	return (
		<RangePicker
			{...pickerProps}
			value={normalizedValue}
			onChange={(date, range) => {
				onChange([new Date(range[0]), new Date(range[1])]);
				pickerProps?.onChange?.(date, range);
			}}
		/>
	);
};

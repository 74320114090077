import { Card, Col, Row, Spin } from 'antd';
import { SoundtrackForm } from 'modules/soundtrack/components';
import { useGetSoundtrackQuery } from 'modules/soundtrack/requests/queries';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const EditSoundtrack: FC = () => {
	const { id } = useParams();
	const { data, isFetching, refetch } = useGetSoundtrackQuery({
		id: Number(id),
	});
	const { t } = useTranslation('soundtrack', { keyPrefix: 'EditSoundtrack' });

	return (
		<Card>
			<Row gutter={[0, 24]}>
				<Col span={24}>
					<h2>
						{t('updateSoundtrack')} (id: {data?.id}) {data?.title || '-'}
					</h2>
				</Col>
				<Col span={24}>
					<Spin spinning={isFetching}>
						<SoundtrackForm
							key={`updateSoundtrack${isFetching}`}
							refetch={refetch}
							soundtrack={data}
						/>
					</Spin>
				</Col>
			</Row>
		</Card>
	);
};

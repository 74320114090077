export enum PathEnum {
	SIGN_IN = '/sign-in',
	AUTHORS = '/authors',
	AUTHOR = '/authors/:id',
	CATEGORIES = '/categories',
	CATEGORY_EDIT = '/categories/:id',
	HOME_CONTENT = '/home-content',
	UPCOMING_CATEGORIES = '/upcoming-categories',
	SOUNDTRACKS = '/soundtracks',
	SOUNDTRACK_EDIT = '/soundtracks/:id',
	SOUNDTRACK_CREATE = '/soundtracks/create',
	USERS = '/users',
	USER_MESSAGES = '/user-messages',
}

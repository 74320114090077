import { Modal } from 'antd';
import { SignInForm } from 'modules/auth/components';
import { FC } from 'react';
import styles from './styles.module.css';

export const SignIn: FC = () => {
	return (
		<Modal
			open
			footer={false}
			closable={false}
			wrapClassName={styles.blurBackground}
		>
			<SignInForm />
		</Modal>
	);
};

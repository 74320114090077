import { Config } from 'config';
import { LocalStorageKeysEnum } from 'modules/common/enums';
import { mutex } from 'modules/common/services/mutex';
import { LogEvent } from 'pino';

export class LogsSenderServices {
	private async sendLogs(logs: LogEvent[]) {
		const normalizedLogs = logs.map((log) => {
			return {
				messages: log.messages,
				level: log.level.label,
				time: new Date(log.ts),
			};
		});

		await mutex.waitForUnlock();
		try {
			const res = await fetch(`${process.env.REACT_APP_API_URL}/logger/admin`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization:
						'Bearer ' +
							localStorage.getItem(LocalStorageKeysEnum.ACCESS_TOKEN) || '',
				},
				body: JSON.stringify({ logs: normalizedLogs }),
			});

			if (!res.ok) return;

			this.removeLogs();
		} catch (e) {
			console.error(e);
		}
	}

	pushLog(log: LogEvent) {
		const logs: LogEvent[] = JSON.parse(
			localStorage.getItem(LocalStorageKeysEnum.LOGS) || '[]'
		);

		if (logs.length >= Config.MAX_LOGS_COUNT || log.level.label === 'error') {
			this.sendLogs([...logs, log]);
		}

		localStorage.setItem(
			LocalStorageKeysEnum.LOGS,
			JSON.stringify([...logs, log])
		);
	}

	removeLogs() {
		localStorage.removeItem(LocalStorageKeysEnum.LOGS);
	}

	runTimeoutSendLogs() {
		setTimeout(() => {
			this.sendExistedLogs();
		}, Config.LOGS_SEND_TIMEOUT_MS);
	}

	sendExistedLogs() {
		const logs: LogEvent[] = JSON.parse(
			localStorage.getItem(LocalStorageKeysEnum.LOGS) || '[]'
		);

		if (logs.length > 0) {
			this.sendLogs(logs);
		}
	}
}

const logsSenderServices = new LogsSenderServices();

logsSenderServices.sendExistedLogs();
logsSenderServices.runTimeoutSendLogs();

export { logsSenderServices };

import { Auth, Common, NoAuth } from 'modules/auth/components';
import { useGetUserQuery } from 'modules/auth/requests/queries';
import { PageDefenseEnum, PathEnum } from 'modules/common/enums';
import { PageType } from 'modules/common/types';
import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Skeleton } from 'antd';
import { Layout } from '../Layout';
import { RoleGuard } from 'modules/auth/components/RoleGuard';

type PropTypes = {
	pages: PageType[];
};

const AuthWrapperByType = {
	[PageDefenseEnum.AUTH]: Auth,
	[PageDefenseEnum.NO_AUTH]: NoAuth,
	[PageDefenseEnum.COMMON]: Common,
};

export const Pages: FC<PropTypes> = ({ pages }) => {
	const { data: user, isLoading } = useGetUserQuery();

	return (
		<Routes>
			<Route element={<Layout />}>
				{pages.map((page) => {
					const Component = page.component;
					const AuthWrapper = AuthWrapperByType[page.pageDefense];
					return (
						<Route
							key={page.path}
							path={page.path}
							element={
								isLoading ? (
									<Skeleton />
								) : (
									<AuthWrapper user={user}>
										<RoleGuard user={user} rolesAllowed={page.rolesAllowed}>
											<Component />
										</RoleGuard>
									</AuthWrapper>
								)
							}
						/>
					);
				})}
				<Route path='*' element={<Navigate to={PathEnum.CATEGORIES} />} />
			</Route>
		</Routes>
	);
};

import { Col, Row } from 'antd';
import { HolidayCategories } from 'modules/category/components/HolidayCategories';
import { WeeklyCategories } from 'modules/category/components/WeeklyCategories';
import { FC } from 'react';

export const UpcomingCategories: FC = () => {
	return (
		<Row gutter={[0, 12]}>
			<Col span={24}>
				<WeeklyCategories />
			</Col>
			<Col span={24}>
				<HolidayCategories />
			</Col>
		</Row>
	);
};

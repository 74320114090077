import { message } from 'antd';
import { SoundtrackActionEnum } from 'modules/category/enums';
import { useSoundtracksAction } from 'modules/category/providers/soundtracksProvider';
import { useRebaseSoundtracksToCategoryMutation } from 'modules/category/requests/mutations';
import { useLazyGetCategoryByIdQuery } from 'modules/category/requests/queries';
import { Button } from 'modules/common/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type PropsType = {
	currentCategoryId: number;
	refetchAll: () => void;
};

export const SoundtracksBufferInfo: FC<PropsType> = ({
	currentCategoryId,
	refetchAll,
}) => {
	const { t } = useTranslation('category', {
		keyPrefix: 'SoundtracksBufferInfo',
	});

	const { soundtracksActionInfo, setSoundtracksActionInfo } =
		useSoundtracksAction();
	const [rebaseSoundtracks, rebaseSoundtracksInfo] =
		useRebaseSoundtracksToCategoryMutation();
	const [getPreviousCategory] = useLazyGetCategoryByIdQuery();

	if (!soundtracksActionInfo?.soundtrackIds?.length) return <div />;

	const onPaste = async () => {
		if (!soundtracksActionInfo.soundtrackIds) return;

		const sourceCategoryId =
			soundtracksActionInfo.action === SoundtrackActionEnum.MOVE
				? soundtracksActionInfo.categoryId
				: undefined;

		try {
			await rebaseSoundtracks({
				itemIds: soundtracksActionInfo.soundtrackIds,
				pointedCategoryId: currentCategoryId,
				sourceCategoryId,
			});

			if (currentCategoryId !== soundtracksActionInfo.categoryId) {
				getPreviousCategory(soundtracksActionInfo.categoryId);
			}

			message.success(t('insertSuccess'));

			refetchAll();
			setSoundtracksActionInfo();
		} catch (e) {
			message.error(t('insertError'));
		}
	};

	const clean = () => {
		setSoundtracksActionInfo();
	};

	return (
		<div>
			{t('itemsSelected', {
				count: soundtracksActionInfo.soundtrackIds.length,
				action: t(soundtracksActionInfo.action),
			})}

			<Button
				loading={rebaseSoundtracksInfo.isLoading}
				onClick={onPaste}
				type='link'
			>
				{t('pasteHere')}
			</Button>
			<Button danger onClick={clean} type='link'>
				{t('clean')}
			</Button>
		</div>
	);
};

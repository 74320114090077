import { SelectProps, Select } from 'antd';
import { FC } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';

type ControlledSelectProps = {
	useControllerProps: UseControllerProps<any>;
	selectProps?: SelectProps;
};

export const ControlledSelect: FC<ControlledSelectProps> = ({
	useControllerProps,
	selectProps,
}) => {
	const { t } = useTranslation('common', { keyPrefix: 'ControlledSelect' });
	const {
		field: { onChange, onBlur, value },
	} = useController(useControllerProps);

	const nullOption = {
		label: t('notSelected'),
		value: '',
	};

	const {mode, options = [], ...restSelectProps } = selectProps || {};

	return (
		<Select
			className={styles.select}
			onBlur={onBlur}
			value={value}
			onChange={onChange}
			mode={mode}
			options={[
				...(!mode?[nullOption]:[]), ...options]}
			allowClear
			{...restSelectProps}
			onClear={() => {
				onChange(null);
			}}
			optionRender={(option) => {
				if (!option.value) {
					return <div className={styles.nullSelected}>{option.label}</div>;
				}
				return option.label;
			}}
		/>
	);
};

import { Tag } from 'antd';
import { UploadStatusesEnum } from 'modules/common/enums';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const statusColorByName = {
	[UploadStatusesEnum.PENDING]: 'blue',
	[UploadStatusesEnum.UPLOADED]: 'green',
	[UploadStatusesEnum.ERROR]: 'red',
};

type PropsType = {
	status: UploadStatusesEnum;
};

export const UploadResultStatus: FC<PropsType> = ({ status }) => {
	const { t } = useTranslation('common', { keyPrefix: 'UploadResultStatus' });

	return <Tag color={statusColorByName[status]}>{t(status)}</Tag>;
};

import { Button, Col, Modal, Row, Spin, Tag } from 'antd';
import { FC, useEffect, useState } from 'react';
import { CategoryItemsTable } from '../CategoryItemsTable';
import { CategoryForm } from '../CategoryForm';
import { useTranslation } from 'react-i18next';
import { SoundtracksProvider } from 'modules/category/providers/soundtracksProvider';
import { useGenerateCategoryFilesMutation } from 'modules/category/requests/mutations';
import {
	useGetCategoryByIdQuery,
	useGetCategoryUploadResultsQuery,
} from 'modules/category/requests/queries';
import { CategoryLibraryIdByLanguage } from 'constants/categoryLibraryByLanguage';
import { useLocalizedContent } from 'modules/common/providers';
import {
	AppstoreAddOutlined,
	PlusOutlined,
	ReloadOutlined,
} from '@ant-design/icons';
import { UploadedCategoryTable } from '../UploadedCategoryTable';
import { UploadStatusesEnum } from 'modules/common/enums/uploadStatuses';
import { Config } from 'config';
import { AddItemsForm } from '../AddItemsForm';
import { SoundtrackForm } from 'modules/soundtrack/components';

import styles from './styles.module.css';

// TODO mb move logic with results to separate component
const statusColorByName = {
	[UploadStatusesEnum.PENDING]: 'blue',
	[UploadStatusesEnum.UPLOADED]: 'green',
	[UploadStatusesEnum.ERROR]: 'red',
};

type PropsType = {
	categoryId: number;
	refetchCategoryList: () => Promise<void>;
};

export const CategoryContentSettings: FC<PropsType> = ({
	categoryId,
	refetchCategoryList,
}) => {
	const { t } = useTranslation('category', {
		keyPrefix: 'CategoryContentSettings',
	});
	const [isCreateSoundtrackModalVisible, setIsCreateSoundtrackModalVisible] =
		useState(false);
	const [isAddItemsModalVisible, setIsAddItemsModalVisible] = useState(false);
	const [isEditModalVisible, setIsEditModalVisible] = useState(false);
	const { locale } = useLocalizedContent();
	const [generateCategoryFiles, { isLoading: isGenerateFilesLoading }] =
		useGenerateCategoryFilesMutation();
	const [isUploadResultModalVisible, setIsUploadResultModalVisible] =
		useState(false);
	const {
		data: uploadInfo,
		isFetching: isResultsLoading,
		refetch: refetchResults,
	} = useGetCategoryUploadResultsQuery(categoryId);
	const {
		data: category,
		refetch: refetchCategory,
		isFetching: isCategoryFetching,
	} = useGetCategoryByIdQuery(
		categoryId || CategoryLibraryIdByLanguage[locale]
	);

	const refetchAll = async () => {
		await Promise.all([refetchCategoryList(), refetchCategory()]);
	};

	useEffect(() => {
		refetchResults();
	}, [categoryId]);

	useEffect(() => {
		if (uploadInfo?.status === UploadStatusesEnum.PENDING) {
			const interval = setInterval(() => {
				refetchResults();
			}, Config.REFETCH_UPLOAD_RESULTS_INTERVAL_MS);

			return () => clearInterval(interval);
		}

		if (uploadInfo?.status === UploadStatusesEnum.UPLOADED) {
			refetchAll();
		}
	}, [uploadInfo?.status]);

	const closeEditModal = () => {
		setIsEditModalVisible(false);
	};

	const onEditClick = () => {
		setIsEditModalVisible(true);
	};

	const onOpenResultsModal = () => {
		setIsUploadResultModalVisible(true);
	};

	const onCloseResultsModal = () => {
		setIsUploadResultModalVisible(false);
	};

	const onAddItemsModalOpen = () => {
		setIsAddItemsModalVisible(true);
	};

	const onAddItemsModalClose = () => {
		setIsAddItemsModalVisible(false);
	};

	const onGenerateJsonsClick = async () => {
		if (!category?.id) return;

		await generateCategoryFiles(category.id);
		refetchResults();
	};

	const onOpenSoundtrackForm = () => {
		setIsCreateSoundtrackModalVisible(true);
	};

	const onCloseSoundtrackForm = () => {
		setIsCreateSoundtrackModalVisible(false);
	};

	const onRefetchClick = () => {
		refetchCategory();
		refetchResults();
	};

	const status = isResultsLoading
		? UploadStatusesEnum.PENDING
		: uploadInfo?.status || UploadStatusesEnum.ERROR;

	return (
		<SoundtracksProvider>
			<Row gutter={[24, 24]}>
				<Col span={24}>
					<Row justify='space-between'>
						<Col span={24}>
							<Row align='middle' gutter={[12, 24]}>
								<Col>
									<h2>
										{t('category')}: {category?.id} - {category?.name}
									</h2>
								</Col>
								<Col>
									{t('resultStatus')}:{' '}
									<Tag color={statusColorByName[status]}>{t(status)}</Tag>
								</Col>
								{uploadInfo?.status !== UploadStatusesEnum.ERROR && (
									<Col>
										<Button type='link' onClick={onOpenResultsModal}>
											{t('openResults')}
										</Button>
									</Col>
								)}
							</Row>
						</Col>
						<Col span={24}>
							<Row gutter={[12, 24]}>
								{category?.isActive && (
									<Col>
										<Button
											loading={isGenerateFilesLoading}
											onClick={onGenerateJsonsClick}
										>
											{t('generateJsons')}
										</Button>
									</Col>
								)}
								<Col>
									<Button onClick={onEditClick} type='link'>
										{t('editCategory')}
									</Button>
								</Col>
								<Col span={24}>
									<Row gutter={[12, 24]}>
										<Col>
											<Button
												type='link'
												icon={<ReloadOutlined />}
												onClick={onRefetchClick}
											/>
										</Col>
										<Col>
											<Button
												type='primary'
												icon={<PlusOutlined />}
												onClick={onOpenSoundtrackForm}
											>
												{t('createItem')}
											</Button>
										</Col>
										{!category?.childrenCount && (
											<Col>
												<Button
													onClick={onAddItemsModalOpen}
													icon={<AppstoreAddOutlined />}
													type='link'
												>
													{t('addItems')}
												</Button>
											</Col>
										)}
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
				<Col>
					<CategoryItemsTable
						categoryId={categoryId}
						refetchAll={refetchAll}
						soundtracks={category?.items || []}
						isCategoryItemsLoading={isCategoryFetching}
					/>
				</Col>
			</Row>
			<Modal footer={false} open={isEditModalVisible} onCancel={closeEditModal}>
				<h2>{t('editCategory')}</h2>
				<CategoryForm
					closeModal={closeEditModal}
					category={category}
					refetch={refetchAll}
				/>
			</Modal>
			<Modal
				width={800}
				footer={false}
				open={isUploadResultModalVisible}
				onCancel={onCloseResultsModal}
			>
				<Spin spinning={isResultsLoading}>
					<UploadedCategoryTable results={uploadInfo?.results || []} />
				</Spin>
			</Modal>
			<Modal
				width={1400}
				footer={false}
				open={isAddItemsModalVisible}
				onCancel={onAddItemsModalClose}
			>
				<AddItemsForm
					categoryId={categoryId}
					refetch={refetchAll}
					categorySoundtracks={category?.items || []}
				/>
			</Modal>
			<Modal
				width={700}
				footer={false}
				open={isCreateSoundtrackModalVisible}
				onCancel={onCloseSoundtrackForm}
			>
				<h2>{t('createItem')}</h2>
				<div className={styles.createSoundtrackContainer}>
					<SoundtrackForm
						soundtrack={{
							categories: category ? [category] : undefined,
						}}
						onComplete={onCloseSoundtrackForm}
						refetch={refetchAll}
					/>
				</div>
			</Modal>
		</SoundtracksProvider>
	);
};

import { useGetAllAuthorsQuery } from 'modules/author/requests/queries';
import { ControlledSelect } from 'modules/common/components';
import { FC } from 'react';
import { Control } from 'react-hook-form';

type PropsType = {
	control: Control<any>;
	isError?: boolean;
};

export const AuthorsField: FC<PropsType> = ({ control, isError }) => {
	const { data: authors, isLoading: isAuthorsLoading } =
		useGetAllAuthorsQuery();

	return (
		<ControlledSelect
			useControllerProps={{
				name: 'author',
				control,
			}}
			selectProps={{
				allowClear: false,
				status: isError ? 'error' : undefined,
				loading: isAuthorsLoading,
				options: authors?.map((author) => ({
					label: `${author.name.en} / ${author.name.he}`,
					value: author.id,
				})),
			}}
		/>
	);
};

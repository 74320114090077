import { FC, useState } from 'react';
import { Button, Card, Col, Input, List, Modal, Row, message } from 'antd';
import { useGenerateAuthorJsonsMutation } from 'modules/author/requests/mutations';
import { useGetAuthorsQuery } from 'modules/author/requests/queries';
import { useTranslation } from 'react-i18next';
import { AuthorForm } from 'modules/author/components';
import { AuthorListItem } from 'modules/author/components/AuthorListItem';
import { AuthorsUploadInfoType } from 'modules/author/types';
import { AuthorUploadResults } from 'modules/author/components/AuthorUploadResults';

export const AuthorList: FC = () => {
	const { t } = useTranslation('author', {
		keyPrefix: 'AuthorList',
	});
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [search, setSearch] = useState<string | undefined>();
	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
	const [generateAuthorJsons, generateAuthorJsonsInfo] =
		useGenerateAuthorJsonsMutation();
	const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
	const [uploadResults, setUploadResults] = useState<AuthorsUploadInfoType>();

	const { data, isFetching: isAuthorsLoading } = useGetAuthorsQuery({
		page,
		limit,
		search,
	});

	const onOpenCreateAuthorModal = () => {
		setIsCreateModalOpen(true);
	};

	const onCloseCreateAuthorModal = () => {
		setIsCreateModalOpen(false);
	};

	const onGenerateJsonsClick = async () => {
		const res = await generateAuthorJsons().unwrap();

		setUploadResults(res);

		return message.success(t('jsonsGenerated'));
	};

	const onCloseErrorModal = () => {
		setIsErrorModalOpen(false);
	};

	const onOpenErrorModal = () => {
		setIsErrorModalOpen(true);
	};

	const onSearch = (value: string) => {
		setSearch(value);
		setPage(1);
	};

	return (
		<Card>
			<Row gutter={[0, 24]}>
				<Col span={24}>
					<Row gutter={[24, 0]}>
						<Col span={12}>
							<Input.Search
								placeholder={t('searchPlaceholder')}
								onSearch={onSearch}
								loading={isAuthorsLoading}
							/>
						</Col>
						<Col>
							<Button type='primary' onClick={onOpenCreateAuthorModal}>
								{t('createAuthor')}
							</Button>
						</Col>
						<Col>
							<Button
								onClick={onGenerateJsonsClick}
								loading={generateAuthorJsonsInfo.isLoading}
							>
								{t('generateFiles')}
							</Button>
						</Col>
						{uploadResults && (
							<Col>
								<Button type='link' onClick={onOpenErrorModal}>
									{t('showResults')}
								</Button>
							</Col>
						)}
					</Row>
				</Col>
				<Col span={24}>
					<List
						loading={isAuthorsLoading}
						pagination={{
							current: page,
							pageSize: limit,
							total: data?.total,
							onChange: (page, limit) => {
								setPage(page);
								setLimit(limit);
							},
						}}
						dataSource={data?.authors}
						renderItem={(author) => <AuthorListItem author={author} />}
					/>
				</Col>
			</Row>
			<Modal
				onCancel={onCloseCreateAuthorModal}
				footer={false}
				open={isCreateModalOpen}
			>
				<h2>{t('createAuthor')}</h2>
				<AuthorForm />
			</Modal>
			<Modal
				footer={false}
				open={isErrorModalOpen}
				onCancel={onCloseErrorModal}
			>
				{uploadResults && <AuthorUploadResults uploadResults={uploadResults} />}
			</Modal>
		</Card>
	);
};

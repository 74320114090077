import { DataNode, EventDataNode } from 'antd/es/tree';
import { Key } from 'react';
import { CategoryTreeDataType, CategoryType } from '../types';
import { TreeItem } from '../components/TreeItem';

class CategoryTreeServices {
	updateTreeData(list: DataNode[], key: Key, children: DataNode[]): DataNode[] {
		return list.map((node) => {
			if (node.key === key) {
				return {
					...node,
					children,
				};
			}
			if (node.children) {
				return {
					...node,
					children: this.updateTreeData(node.children, key, children),
				};
			}
			return node;
		});
	}

	removeCategoryFromTree({
		dragNode,
		data,
	}: {
		dragNode: EventDataNode<DataNode>;
		data: DataNode[];
	}): DataNode[] {
		const filteredList = data.filter((item) => item.key !== dragNode.key);

		return filteredList.map((item) => {
			if (!item.children) return item;

			const cleanedChildren = this.removeCategoryFromTree({
				dragNode,
				data: item.children,
			});

			return {
				...item,
				isLeaf: cleanedChildren.length === 0,
				children: cleanedChildren,
			};
		});
	}

	insertOver({
		dragNode,
		node,
		data,
	}: {
		dragNode: EventDataNode<DataNode>;
		node: EventDataNode<DataNode>;
		data: DataNode[];
	}) {
		return data.map<DataNode>((item): DataNode => {
			if (node.key === item.key) {
				return {
					...item,
					isLeaf: false,
					children: [dragNode, ...(item.children || [])],
				};
			} else if (item.children) {
				return {
					...item,
					children: this.insertOver({ dragNode, node, data: item.children }),
				};
			}
			return item;
		});
	}

	insertInFlat({
		dragNode,
		node,
		data,
		order,
	}: {
		dragNode: EventDataNode<DataNode>;
		node: EventDataNode<DataNode>;
		data: DataNode[];
		order: 'before' | 'after';
	}): DataNode[] {
		return data.reduce<DataNode[]>((acc: DataNode[], item: DataNode) => {
			if (node.key === item.key) {
				return order === 'after'
					? [...acc, item, dragNode]
					: [...acc, dragNode, item];
			}
			if (item.children) {
				return [
					...acc,
					{
						...item,
						children: this.insertInFlat({
							dragNode,
							node,
							data: item.children,
							order,
						}),
					},
				];
			}
			return [...acc, item];
		}, []);
	}

	insertCategoryIntoTree({
		dragNode,
		node,
		data,
	}: {
		dragNode: EventDataNode<DataNode>;
		node: EventDataNode<DataNode>;
		data: DataNode[];
	}) {
		const { dragOver, dragOverGapBottom } = node;

		if (dragOver) return this.insertOver({ dragNode, node, data });

		const order = dragOverGapBottom ? 'after' : 'before';
		return this.insertInFlat({ dragNode, node, data, order });
	}

	updateCategoriesOrder({
		dragNode,
		node,
		data,
	}: {
		dragNode: EventDataNode<DataNode>;
		node: EventDataNode<DataNode>;
		data: DataNode[];
	}) {
		const cleanedData = this.removeCategoryFromTree({ dragNode, data });

		return this.insertCategoryIntoTree({ dragNode, node, data: cleanedData });
	}

	formateData(category: CategoryType): DataNode {
		return {
			key: category.id,
			title: <TreeItem category={category} />,
			isLeaf: category.childrenCount === 0,
		};
	}

	formateDataTreeSelect(category: CategoryType): CategoryTreeDataType {
		return {
			id: category.id,
			pId: category.parentCategoryId,
			title: `(id: ${category.id}) ${category.name || '-'}`,
			isLeaf: category.childrenCount === 0,
			value: category.id,
		};
	}
}

export const categoryTreeServices = new CategoryTreeServices();

import {
	fetchBaseQuery,
	BaseQueryFn,
	FetchArgs,
	FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { message } from 'antd';
import { tokensServices } from 'modules/auth/services';
import { mutex } from 'modules/common/services/mutex';
import { logger } from 'modules/logger/services';
import { PathEnum } from 'modules/common/enums';

const baseQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_API_URL,
	prepareHeaders: (headers) => {
		headers.set('Authorization', `Bearer ${tokensServices.getAccessToken()}`);
	},
	responseHandler: async (response) => {
		if (!response.ok && response.status !== 401) {
			message.error(response.statusText);
		}

		return response.json();
	},
});

export const baseQueryWithReauth: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError
> = async (args, api, extraOptions) => {
	await mutex.waitForUnlock();

	const result = await baseQuery(args, api, extraOptions);

	if (result.error && result.error.status === 401 && !mutex.isLocked()) {
		const release = await mutex.acquire();
		try {
			await tokensServices.refreshTokens();
		} catch (error) {
			tokensServices.removeTokens();
			if (window.location.pathname !== PathEnum.SIGN_IN) {
				window.location.replace(PathEnum.SIGN_IN);
			}
		}
		release();

		return baseQuery(args, api, extraOptions);
	} else if (result.error && result.error.status === 401) {
		await mutex.waitForUnlock();
		return await baseQuery(args, api, extraOptions);
	}

	if (result.error) {
		logger.error(JSON.stringify({ args, error: result.error }));
	} else {
		logger.info(
			JSON.stringify({
				endpoint: api.endpoint,
				url: typeof args === 'string' ? args : args.url,
			})
		);
	}

	return result;
};

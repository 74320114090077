export enum ItemColumnEnum {
  ID = 'id',
  TITLE = 'title',
  AUTHOR = 'author',
  DIBUR_HAMASCHIL = 'diburHamaschil',
  TYPE = 'type',
  VIEW = 'view',
  ALBUM = 'album',
  ABOUT_PERSON = 'aboutPerson',
  DISC_NUMBER = 'discNumber',
  SIMAN = 'siman',
  YEAR_OF_LECTURE = 'yearOfLecture',
  MONTH_OF_LECTURE = 'monthOfLecture',
  DISTRIBUTION = 'distribution',
  NOTE = 'note',
  DAY_LOOKUP1 = 'dayLookup1',
  DAY_LOOKUP2 = 'dayLookup2',
  CATEGORIES = 'categories',
}

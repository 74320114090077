import {
	GetDynamicCategoriesResponseType,
	HomeContentCDNType,
} from 'modules/category/types';
import { UploadedCategoryInfoType } from 'modules/category/types/uploadedCategoryInfo';
import { CategoryType } from 'modules/category/types/category';
import { LanguageEnum } from 'modules/common/enums';
import { UploadResultType } from 'modules/common/types/uploadResult';
import { hmApi } from 'store/api';

export const {
	useGetCategoriesByParentIdsQuery,
	useLazyGetCategoriesByParentIdsQuery,
	useGetCategoryByIdQuery,
	useLazyGetCategoryByIdQuery,
	useLazyGenerateWeeklyCategoriesJsonQuery,
	useLazyGenerateHolidayCategoriesJsonQuery,
	useGetHolidayCategoriesQuery,
	useGetWeeklyCategoriesQuery,
	useGetHomePageContentQuery,
	useGetCategoryUploadResultsQuery,
} = hmApi.injectEndpoints({
	endpoints: (builder) => ({
		getCategoriesByParentIds: builder.query<CategoryType[], { id: number }>({
			query: ({ id }) => ({
				url: `categories/${id}/subcategories`,
			}),
		}),
		getCategoryById: builder.query<CategoryType, number>({
			query: (id) => `categories/${id}`,
		}),
		generateWeeklyCategoriesJson: builder.query<undefined, void>({
			query: () => ({
				url: 'weekly',
				method: 'post',
			}),
		}),
		generateHolidayCategoriesJson: builder.query<undefined, void>({
			query: () => ({
				url: 'holiday',
				method: 'post',
			}),
		}),
		getWeeklyCategories: builder.query<GetDynamicCategoriesResponseType, void>({
			query: () => ({
				url: 'weekly',
			}),
		}),
		getHolidayCategories: builder.query<GetDynamicCategoriesResponseType, void>(
			{
				query: () => ({
					url: 'holiday',
				}),
			}
		),
		getHomePageContent: builder.query<HomeContentCDNType, LanguageEnum>({
			query: (lang) => `home-content?lang=${lang}`,
		}),
		getCategoryUploadResults: builder.query<
			UploadResultType<UploadedCategoryInfoType>,
			number
		>({
			query: (categoryId) => `categories/files/${categoryId}/results`,
		}),
	}),
});

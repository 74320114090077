import { UploadFile } from 'antd';
import { Config } from 'config';
import * as yup from 'yup';

export const schema = yup.object({
	title: yup.string().required().max(9999),
	subtitle: yup.string().nullable().max(9999),
	categories: yup.array().of(yup.number().required()),
	author: yup.number().required(),
	topics: yup.array().of(yup.number().required()),
	type: yup.number(),
	linkType: yup
		.string()
		.nullable()
		.test('linkType', 'Link type is required', function (value) {
			return !(
				(value && !this.parent.linkTarget) ||
				(!value && this.parent.linkTarget)
			);
		}),
	linkTarget: yup
		.string()
		.test('linkTarget', 'Link target is required', function (value) {
			if (!this.parent.linkType && !value) return true;
			return !(this.parent.linkType && !value);
		})
		.max(9999),
	metadata: yup.string().nullable().max(9999),
	isAllowDownload: yup.boolean().required(),
	isDegraded: yup.boolean().required(),
	forDistribution: yup.boolean().required(),
	hasBookVersion: yup.boolean().required(),
	languageId: yup.number().required(),
	fileList: yup
		.mixed<UploadFile[]>()
		.test('fileList', 'Wrong format', function (fileList) {
			if (!fileList?.length) return true;

			const file = fileList[0];
			if (file.type && Config.MEDIA_FORMATS.includes(file.type)) return true;

			return false;
		}),
});

export type ValidationSchemaType = yup.InferType<typeof schema>;

import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { ValidationSchemaType, schema } from './schema';
import { useTranslation } from 'react-i18next';
import { Button, ControlledInput } from 'modules/common/components';
import { Row, Col, message } from 'antd';
import { useSignInMutation } from 'modules/auth/requests/mutations';
import { tokensServices } from 'modules/auth/services';
import { SignInResponseType } from 'modules/auth/types';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';

export const SignInForm: FC = () => {
	const { t } = useTranslation('auth', { keyPrefix: 'SignInForm' });
	const [sigIn, sigInInfo] = useSignInMutation();
	const { control, handleSubmit } = useForm<ValidationSchemaType>({
		resolver: yupResolver(schema),
	});

	const onSubmit = handleSubmit(async (data) => {
		const res = (await sigIn(data)) as {
			data?: SignInResponseType;
		} & {
			error?: FetchBaseQueryError | SerializedError;
		};

		if (res.error) {
			message.error(t('signInError'));
		}

		if (res.data) {
			tokensServices.addTokensToLocalStorage(res.data);
			window.location.reload();
		}
	});

	return (
		<Row gutter={[0, 12]}>
			<Col span={24}>
				<h1>{t('signIn')}</h1>
			</Col>
			<Col span={24}>
				<ControlledInput
					useControllerProps={{
						control,
						name: 'email',
					}}
					inputProps={{
						placeholder: t('email'),
					}}
				/>
			</Col>
			<Col span={24}>
				<ControlledInput
					useControllerProps={{
						control,
						name: 'password',
					}}
					inputProps={{
						type: 'password',
						placeholder: t('password'),
					}}
				/>
			</Col>
			<Col span={24}>
				<Button onClick={onSubmit} type='primary' loading={sigInInfo.isLoading}>
					{t('signIn')}
				</Button>
			</Col>
		</Row>
	);
};

import { ModuleType } from 'modules/common/types';
import { AuthorList } from './pages/AuthorList';
import { AuthorEdit } from './pages/AuthorEdit';
import { PageDefenseEnum, PathEnum } from 'modules/common/enums';
import { RoleEnum } from 'modules/auth/enums';

export const authorModule: ModuleType = {
	pages: [
		{
			path: PathEnum.AUTHORS,
			component: AuthorList,
			pageDefense: PageDefenseEnum.AUTH,
			rolesAllowed: [RoleEnum.ADMIN],
		},
		{
			path: PathEnum.AUTHOR,
			component: AuthorEdit,
			pageDefense: PageDefenseEnum.AUTH,
			rolesAllowed: [RoleEnum.ADMIN],
		},
	],
};

import { Card, Col, Row, Spin } from 'antd';
import { DataNode } from 'antd/es/tree';
import {
	useGetCategoriesByParentIdsQuery,
	useLazyGetCategoriesByParentIdsQuery,
} from 'modules/category/requests/queries';
import { FC, Key, useEffect, useMemo, useState } from 'react';
import { useLocalizedContent } from 'modules/common/providers';
import { categoryTreeServices } from 'modules/category/services';
import { CategoryLibraryIdByLanguage } from 'constants/categoryLibraryByLanguage';
import { CategoryContentSettings } from 'modules/category/components/CategoryContentSettings';
import { CategoryTreeHeader } from 'modules/category/components/CategoryTreeHeader';
import { CategoryTree } from 'modules/category/components';

export const CategoryTreePage: FC = () => {
	const { locale } = useLocalizedContent();
	const rootCategoryId = useMemo(
		() => CategoryLibraryIdByLanguage[locale],
		[locale]
	);

	const [treeData, setTreeData] = useState<DataNode[]>([]);
	const [fetchCategoriesByParentId] = useLazyGetCategoriesByParentIdsQuery();

	const [selectedCategoryId, setSelectedCategoryId] = useState<number>();
	const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);

	const { data: categories, isLoading: isCategoryTreeLoading } =
		useGetCategoriesByParentIdsQuery({
			id: rootCategoryId,
		});

	const injectCategoriesIntoTree = async (id: number) => {
		const res = await fetchCategoriesByParentId({ id });
		const childCategories = res.data || [];
		const formattedCategories =
			childCategories.map(categoryTreeServices.formateData) || [];
		setTreeData((origin) =>
			categoryTreeServices.updateTreeData(origin, id, formattedCategories)
		);
	};

	const fillExpandedCategories = async (
		categories: DataNode[]
	): Promise<DataNode[]> => {
		const fetchChildren = async (category: DataNode) => {
			if (expandedKeys.includes(category.key)) {
				const { data: children = [] } = await fetchCategoriesByParentId({
					id: Number(category.key),
				});
				return {
					...category,
					children: await fillExpandedCategories(
						children.map(categoryTreeServices.formateData) || []
					),
				};
			}
			return category;
		};

		const tree = categories.map(fetchChildren);
		return await Promise.all(tree);
	};

	const refetchTree = async () => {
		const { data: categories = [] } = await fetchCategoriesByParentId({
			id: rootCategoryId,
		});
		const treeData = categories.map(categoryTreeServices.formateData);
		const filledTree = await fillExpandedCategories(treeData);

		setTreeData(filledTree);
	};

	useEffect(() => {
		if (!categories) return;
		setTreeData(categories.map(categoryTreeServices.formateData));
	}, [categories]);

	useEffect(() => {
		refetchTree();
		setSelectedCategoryId(undefined);
	}, [locale]);

	const onSelectCategory = async (key: Key) => {
		setSelectedCategoryId(Number(key));
	};

	return (
		<Card loading={isCategoryTreeLoading}>
			<Row gutter={[12, 12]}>
				<Col span={24}>
					<CategoryTreeHeader
						refetchTree={refetchTree}
						rootCategoryId={rootCategoryId}
						selectedCategoryId={selectedCategoryId}
						setSelectedCategoryId={setSelectedCategoryId}
					/>
				</Col>
				<Col span={8}>
					<Spin spinning={isCategoryTreeLoading}>
						<CategoryTree
							onSelectCategory={onSelectCategory}
							treeData={treeData}
							setTreeData={setTreeData}
							injectCategoriesIntoTree={injectCategoriesIntoTree}
							expandedKeys={expandedKeys}
							setExpandedKeys={setExpandedKeys}
						/>
					</Spin>
				</Col>
				<Col span={16}>
					{selectedCategoryId && (
						<CategoryContentSettings
							refetchCategoryList={refetchTree}
							categoryId={selectedCategoryId}
						/>
					)}
				</Col>
			</Row>
		</Card>
	);
};

import { Card, Col, Row } from 'antd';
import { PathEnum } from 'modules/common/enums';
import { SoundtrackForm } from 'modules/soundtrack/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const CreateSoundtrack: FC = () => {
	const { t } = useTranslation('soundtrack', {
		keyPrefix: 'CreateSoundtrack',
	});
	const navigate = useNavigate();

	return (
		<Card>
			<Row gutter={[0, 24]}>
				<Col span={24}>
					<h2>{t('createSoundtrack')}</h2>
				</Col>
				<Col span={24}>
					<SoundtrackForm
						onComplete={() => {
							navigate(PathEnum.SOUNDTRACKS);
						}}
					/>
				</Col>
			</Row>
		</Card>
	);
};

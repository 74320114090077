import { Col, Divider, Row } from 'antd';
import { AuthorsUploadInfoType } from 'modules/author/types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

type PropsType = {
	uploadResults: AuthorsUploadInfoType;
};

export const AuthorUploadResults: FC<PropsType> = ({ uploadResults }) => {
	const { t } = useTranslation('author', { keyPrefix: 'AuthorUploadResults' });

	return (
		<Row>
			<Col span={24}>
				<h2>{t('uploadResults')}</h2>
			</Col>
			<Col span={24}>
				<h3>
					{t('enResults')}: {t(uploadResults.enAlphabeticResults.status)}
				</h3>
				{uploadResults.enAlphabeticResults.error && (
					<p>{uploadResults.enAlphabeticResults.error}</p>
				)}
			</Col>
			<Col span={24}>
				<h3>
					{t('enResults')}: {t(uploadResults.heAlphabeticResults.status)}
				</h3>
				{uploadResults.enAlphabeticResults.error && (
					<p>{uploadResults.enAlphabeticResults.error}</p>
				)}
			</Col>
			<Col span={24}>
				<h3>{t('authorsResults')}:</h3>
				<div className={styles.authorsResults}>
					{uploadResults.authorsResults.map((result) => {
						return (
							<div key={result.author.id}>
								<p>
									(id: {result.author.id}){result.author.name.en} (
									{result.author.name.yi}): {t(result.status)}
								</p>
								{result.error && <p>{result.error}</p>}
								<Divider />
							</div>
						);
					})}
				</div>
			</Col>
		</Row>
	);
};

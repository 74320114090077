import { Button, Col, Input, Modal, Row, Table } from 'antd';
import { FC, useState } from 'react';
import { useColumns } from './columns';
import {
	useDeleteUserMessageMutation,
	useGetUserMessagesQuery,
} from 'modules/user-messages/api';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { UserMessageForm } from '../UserMessageForm';
import { UserMessageType } from 'modules/user-messages/types';

const { Search } = Input;

export const UserMessageTable: FC = () => {
	const { t } = useTranslation('userMessages', {
		keyPrefix: 'UserMessagesTable',
	});
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	const [searchText, setSearchText] = useState('');
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedMessage, setSelectedMessage] = useState<
		UserMessageType | undefined
	>();

	const [deleteMessage, deleteMessageInfo] = useDeleteUserMessageMutation();
	const response = useGetUserMessagesQuery({
		search: searchText,
		page,
		limit,
	});

	const onCreateClick = () => {
		setIsModalOpen(true);
	};

	const onEditClick = (message: UserMessageType) => {
		setSelectedMessage(message);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setSelectedMessage(undefined);
		setIsModalOpen(false);
	};

	const onDeleteRecord = async (id: number) => {
		await deleteMessage(id);
		response.refetch();
	};

	const messages = response.data?.messages || [];
	const total = response.data?.total || 0;
	const columns = useColumns({ onEditClick, onDeleteRecord });

	const isLoading = response.isLoading || deleteMessageInfo.isLoading;
	return (
		<Row gutter={[0, 24]}>
			<Col span={24}>
				<Row gutter={[12, 12]}>
					<Col>
						<Button
							onClick={onCreateClick}
							icon={<PlusOutlined />}
							type='primary'
						>
							{t('create')}
						</Button>
					</Col>
					<Col>
						<Search onSearch={setSearchText} />
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<Table
					scroll={{ x: 1000 }}
					rowKey='id'
					loading={isLoading}
					pagination={{
						total: total,
						current: page,
						onChange: (page, pageSize) => {
							setPage(page);
							setLimit(pageSize);
						},
					}}
					dataSource={messages}
					columns={columns}
				/>
			</Col>

			<Modal open={isModalOpen} footer={false} onCancel={closeModal}>
				<UserMessageForm
					closeModal={closeModal}
					refetch={response.refetch}
					userMessage={selectedMessage}
				/>
			</Modal>
		</Row>
	);
};

import { PageDefenseEnum, PathEnum } from 'modules/common/enums';
import { PageType } from 'modules/common/types';
import { Users } from './Users';
import { RoleEnum } from 'modules/auth/enums';

export const pages: PageType[] = [
	{
		path: PathEnum.USERS,
		component: Users,
		pageDefense: PageDefenseEnum.AUTH,
		rolesAllowed: [RoleEnum.ADMIN],
	},
];

import { ItemColumnEnum } from 'modules/category/enums';

export const Config = {
	DEFAULT_ITEMS_SHOWN_COLUMNS: [ItemColumnEnum.TITLE, ItemColumnEnum.AUTHOR],
	DEFAULT_ITEMS_SHOWN_ROWS: 10,
	MAX_LOGS_COUNT: 9,
	LOGS_SEND_TIMEOUT_MS: 10000, // 10 seconds
	REFETCH_UPLOAD_RESULTS_INTERVAL_MS: 5000, // 5 seconds
	AUTHOR_IMAGE_SIZE_BITES: 1024 * 1024 * 10, // 10MB
	AUTHOR_IMAGE_TYPES: ['image/jpeg', 'image/png', 'image/webp'],
	MAX_SELECTED_SOUNDTRACKS_COUNT_DISPLAYED: 9,
	MEDIA_FORMATS: ['audio/mpeg'],
	EMAIL_REGEX: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
};

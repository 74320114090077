import { PlusOutlined } from '@ant-design/icons';
import {
	Col,
	Input,
	Radio,
	RadioChangeEvent,
	Row,
	TreeSelectProps,
} from 'antd';
import { CategoryTreeDataType } from 'modules/category/types';
import { Button, TreeSelect } from 'modules/common/components';
import { PathEnum } from 'modules/common/enums';
import {
	Dispatch,
	FC,
	SetStateAction,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';
import { SoundtrackErrorType } from 'modules/soundtrack/types/soundtrackError';
import { useLazyGetCategoriesByParentIdsQuery } from 'modules/category/requests/queries';
import { categoryTreeServices } from 'modules/category/services';
import { CategoryLibraryIdByLanguage } from 'constants/categoryLibraryByLanguage';
import { useLocalizedContent } from 'modules/common/providers';

const errorFilterValueByType: {
	[key: string]: SoundtrackErrorType | undefined;
} = {
	all: undefined,
	errors: 'error',
	noErrors: 'noError',
};

type PropsType = {
	setCategoryIdsFilter: Dispatch<SetStateAction<number[] | undefined>>;
	setSearch: Dispatch<SetStateAction<string | undefined>>;
	setErrorFilter: Dispatch<SetStateAction<SoundtrackErrorType | undefined>>;
	categoryIdsFilter: number[] | undefined;
	hasCreateItem?: boolean;
};

export const SoundtrackTableHeader: FC<PropsType> = ({
	setCategoryIdsFilter,
	setSearch,
	setErrorFilter,
	categoryIdsFilter,
	hasCreateItem = false,
}) => {
	const { locale } = useLocalizedContent();
	const { t } = useTranslation('soundtrack', {
		keyPrefix: 'SoundtrackTableHeader',
	});
	const [fetchCategories, { isLoading: isCategoriesLoading }] =
		useLazyGetCategoriesByParentIdsQuery();
	const [categoryTreeData, setCategoryTreeData] = useState<
		CategoryTreeDataType[]
	>([]);
	const navigate = useNavigate();

	const fetchCategoriesSynk = useCallback(async () => {
		const res = await fetchCategories({
			id: CategoryLibraryIdByLanguage[locale],
		});
		if (res.data) {
			setCategoryTreeData(
				res.data.map(categoryTreeServices.formateDataTreeSelect)
			);
		}
	}, [fetchCategories, locale]);

	useEffect(() => {
		fetchCategoriesSynk();
	}, [fetchCategories, locale, fetchCategoriesSynk]);

	const onErrorFilterChange = (e: RadioChangeEvent) => {
		setErrorFilter(errorFilterValueByType[e.target.value]);
	};

	const onSearchChange = (value: string) => {
		setSearch(value);
	};

	const onLoadTreeData: TreeSelectProps['loadData'] = async ({
		children,
		id,
	}): Promise<void> => {
		if (children) {
			return;
		}

		const res = await fetchCategories({ id });
		const orderedCategories = res.data || [];
		const branch =
			orderedCategories.map(categoryTreeServices.formateDataTreeSelect) || [];

		setCategoryTreeData([...categoryTreeData, ...branch]);
	};

	return (
		<Row align='middle' wrap={false} gutter={[24, 0]}>
			{hasCreateItem && (
				<Col>
					<Button
						type='primary'
						onClick={() => navigate(PathEnum.SOUNDTRACK_CREATE)}
						icon={<PlusOutlined />}
					/>
				</Col>
			)}
			<Col>
				<Radio.Group defaultValue='all' onChange={onErrorFilterChange}>
					<Radio.Button value='all'>{t('all')}</Radio.Button>
					<Radio.Button value='errors'>{t('hasError')}</Radio.Button>
					<Radio.Button value='noErrors'>{t('noError')}</Radio.Button>
				</Radio.Group>
			</Col>

			<Col flex={'45%'}>
				<TreeSelect
					value={categoryIdsFilter}
					onChange={setCategoryIdsFilter}
					placeholder={t('filterByCategory')}
					treeDataSimpleMode
					multiple
					loading={isCategoriesLoading}
					className={styles.categoryFilter}
					loadData={onLoadTreeData}
					treeData={categoryTreeData}
				/>
			</Col>
			<Col flex={'auto'}>
				<Input.Search
					placeholder={t('searchPlaceholder')}
					onSearch={onSearchChange}
				/>
			</Col>
		</Row>
	);
};

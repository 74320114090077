import { ApiBuilderType } from 'modules/common/types';
import { CreateUserMessageType } from 'modules/user-messages/types';

export const create = (builder: ApiBuilderType) =>
	builder.mutation<void, CreateUserMessageType>({
		query: (data) => ({
			url: '/user-messages',
			method: 'POST',
			body: data,
		}),
	});

import { Tree, TreeProps, message } from 'antd';
import { EventDataNode, DataNode } from 'antd/es/tree';
import { OrderPositionEnum } from 'modules/category/enums';
import { useUpdatedCategoryOrderMutation } from 'modules/category/requests/mutations';
import { categoryTreeServices } from 'modules/category/services';
import { useLocalizedContent } from 'modules/common/providers';
import { Dispatch, FC, Key, SetStateAction, useEffect } from 'react';

type PropsType = {
	treeData: DataNode[];
	setTreeData: Dispatch<SetStateAction<DataNode[]>>;
	onSelectCategory: (key: Key) => Promise<void>;
	injectCategoriesIntoTree: (id: number) => Promise<void>;
	expandedKeys: Key[];
	setExpandedKeys: Dispatch<SetStateAction<Key[]>>;
};

export const CategoryTree: FC<PropsType> = ({
	treeData,
	setTreeData,
	onSelectCategory,
	injectCategoriesIntoTree,
	expandedKeys,
	setExpandedKeys,
}) => {
	const [updatedCategoryOrder] = useUpdatedCategoryOrderMutation();
	const { locale } = useLocalizedContent();

	const updateTreeDataAfterDragging: TreeProps['onDrop'] = (info) => {
		const { node, dragNode } = info;

		const updatedTree = categoryTreeServices.updateCategoriesOrder({
			data: treeData,
			dragNode,
			node,
		});

		return setTreeData(updatedTree);
	};

	useEffect(() => {
		setExpandedKeys([]);
	}, [locale]);

	const onDrop: TreeProps['onDrop'] = async (info) => {
		const { dragNode, node } = info;

		const positions = {
			[OrderPositionEnum.BEFORE]: node.dragOverGapTop,
			[OrderPositionEnum.AFTER]: node.dragOverGapBottom,
			[OrderPositionEnum.INSIDE]: node.dragOver,
		};
		const position =
			Object.entries(positions).find((record) => record[1])?.[0] || 'inside';

		updateTreeDataAfterDragging(info);

		(async () => {
			await updatedCategoryOrder({
				draggedCategoryId: dragNode.key as unknown as number,
				pointedCategoryId: node.key as unknown as number,
				position: position as OrderPositionEnum,
			});

			message.success(`Category ${dragNode.key} moved successfully`);
		})();
	};

	const expandExpandedChildren = async (children: DataNode[]) => {
		children.forEach(async (child) => {
			const isExpanded = expandedKeys.includes(child.key);
			if (isExpanded) {
				await injectCategoriesIntoTree(child.key as number);
			}
			if (child.children) {
				expandExpandedChildren(child.children);
			}
		});
	};

	const onLoadCategoryTreeData = async ({
		key,
		children,
	}: EventDataNode<DataNode>): Promise<void> => {
		await injectCategoriesIntoTree(key as number);
		children && expandExpandedChildren(children);
	};

	return (
		<Tree
			onDrop={onDrop}
			onClick={(_, info) => {
				onSelectCategory(info.key);
			}}
			loadData={onLoadCategoryTreeData}
			draggable
			expandedKeys={expandedKeys}
			treeData={treeData}
			className='draggable-tree'
			onExpand={(keys, { expanded, node }) => {
				setExpandedKeys(keys);
				if (!expanded) return;
				onLoadCategoryTreeData(node);
			}}
		/>
	);
};

import { LanguageIdByName } from 'constants/languageIdByName';
import { ControlledSelect } from 'modules/common/components';
import { FC, useMemo } from 'react';
import { Control, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type PropsType = {
	control: Control<any>;
	error?: FieldError | undefined;
};

export const LanguageIdField: FC<PropsType> = ({ control, error }) => {
	const { t } = useTranslation('soundtrack', { keyPrefix: 'SoundtrackForm' });
	const options = useMemo(
		() =>
			Object.entries(LanguageIdByName).map(([key, value]) => ({
				label: t(key),
				value,
			})),
		[t]
	);

	return (
		<ControlledSelect
			useControllerProps={{
				name: 'languageId',
				control,
			}}
			selectProps={{
				options,
				status: error ? 'error' : undefined,
			}}
		/>
	);
};

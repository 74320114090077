import { Col, Row, message } from 'antd';
import { Button, Select } from 'modules/common/components';
import { Dispatch, FC, Key, SetStateAction, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SoundtracksBufferInfo } from '../SoundtracksBufferInfo';
import { useSoundtracksAction } from 'modules/category/providers/soundtracksProvider';
import { useUpdateCategoryItemsMutation } from 'modules/category/requests/mutations';
import { ItemColumnEnum, SoundtrackActionEnum } from 'modules/category/enums';
import { SoundtrackType } from 'modules/soundtrack/types';

import styles from './styles.module.css';
import { Config } from 'config';
import { itemColumnsServices } from 'modules/category/services';
import { ColumnsType } from 'antd/es/table';
import { DefaultOptionType } from 'antd/es/select';

type PropsType = {
	selectedKeys: Key[];
	refetchAll: () => void;
	setSelectedKeys: Dispatch<SetStateAction<Key[]>>;
	soundtracks: SoundtrackType[];
	categoryId: number;
	columns: ColumnsType<SoundtrackType>;
	shownColumnKeys: ItemColumnEnum[];
	setShownColumnKeys: Dispatch<SetStateAction<ItemColumnEnum[]>>;
};

export const CategoryItemsHeader: FC<PropsType> = ({
	selectedKeys,
	refetchAll,
	setSelectedKeys,
	soundtracks,
	categoryId,
	columns,
	shownColumnKeys,
	setShownColumnKeys,
}) => {
	const { t } = useTranslation('category', {
		keyPrefix: 'CategoryItemsHeader',
	});
	const { soundtracksActionInfo, setSoundtracksActionInfo } =
		useSoundtracksAction();
	const [updateCategoryItems, updateCategoryItemsInfo] =
		useUpdateCategoryItemsMutation();

	const shownColumnOptions = useMemo<DefaultOptionType[]>(
		() =>
			columns
				.filter((column) => !!column.title)
				.map((column) => ({
					label: column.title?.toString(),
					value: column.key?.toString(),
				})),
		[columns]
	);

	useEffect(() => {
		const savedColumns = itemColumnsServices.getColumnsConfig();

		if (savedColumns?.length) {
			return setShownColumnKeys(savedColumns);
		}

		setShownColumnKeys(Config.DEFAULT_ITEMS_SHOWN_COLUMNS);
		itemColumnsServices.addColumnsConfig(Config.DEFAULT_ITEMS_SHOWN_COLUMNS);
	}, []);

	const onColumnSelect = (selectedColumns: ItemColumnEnum[]) => {
		itemColumnsServices.addColumnsConfig(selectedColumns);
		setShownColumnKeys(selectedColumns);
	};

	const removeItems = async () => {
		const updatedItems = soundtracks.filter(
			(soundtrack) => !selectedKeys.some((key) => key === soundtrack.id)
		);

		const updatedItemsIds = updatedItems.map((item) => item.id);

		try {
			await updateCategoryItems({
				categoryId,
				itemIds: updatedItemsIds,
			});
			setSelectedKeys([]);
		} catch (e) {
			message.error('deleteItemsError');
		}

		refetchAll();
	};

	const onCopy = () => {
		setSoundtracksActionInfo({
			action: SoundtrackActionEnum.COPY,
			soundtrackIds: selectedKeys.map((key) => Number(key)),
			categoryId,
		});
		setSelectedKeys([]);
	};

	const onMove = () => {
		setSoundtracksActionInfo({
			action: SoundtrackActionEnum.MOVE,
			soundtrackIds: selectedKeys.map((key) => Number(key)),
			categoryId,
		});
		setSelectedKeys([]);
	};

	return (
		<Row gutter={[12, 24]} align='middle'>
			<Col>
				<Select
					value={shownColumnKeys}
					onChange={onColumnSelect}
					placeholder={t('shownColumns')}
					className={styles.selectColumns}
					options={shownColumnOptions}
					mode='multiple'
					maxTagCount='responsive'
				/>
			</Col>
			<Col>
				<Button
					disabled={selectedKeys.length === 0}
					loading={updateCategoryItemsInfo.isLoading}
					type='primary'
					onClick={removeItems}
					danger
				>
					{t('delete')}
				</Button>
			</Col>
			<Col>
				<Button
					disabled={selectedKeys.length === 0}
					onClick={onMove}
					type='primary'
				>
					{t('move')}
				</Button>
			</Col>
			<Col>
				<Button disabled={selectedKeys.length === 0} onClick={onCopy}>
					{t('copy')}
				</Button>
			</Col>
			{!soundtracksActionInfo?.soundtrackIds?.length ? (
				<Col>
					{t('selectedKeys')}: {selectedKeys?.length || 0}
				</Col>
			) : (
				<Col>
					<SoundtracksBufferInfo
						refetchAll={refetchAll}
						currentCategoryId={categoryId}
					/>
				</Col>
			)}
		</Row>
	);
};

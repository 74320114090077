import { UserOutlined } from '@ant-design/icons';
import { List, Avatar } from 'antd';
import { FC, useState } from 'react';
import { UploadButton } from '../UploadButton';
import { AuthorType } from 'modules/author/types';

type PropsType = {
	author: AuthorType;
};

// TODO logic of redirect is commented because page is not ready yet
export const AuthorListItem: FC<PropsType> = ({ author }) => {
	// const { t } = useTranslation('author', {
	// 	keyPrefix: 'AuthorListItem',
	// });
	// const navigate = useNavigate();
	const [imageCache, setImageCache] = useState(new Date());

	// const onToAuthorPage = () => {
	// 	navigate(author.id.toString());
	// };

	return (
		<List.Item
			actions={[
				<UploadButton
					key={`upload-action:${author.id}`}
					authorId={author.id}
					setImageCache={setImageCache}
				/>,
				// TODO page is not ready yet
				// <Button
				// 	key={`navigate-action:${author.id}`}
				// 	type='link'
				// 	onClick={onToAuthorPage}
				// >
				// 	{t('view')}
				// </Button>,
			]}
		>
			<List.Item.Meta
				avatar={
					<Avatar
						shape='square'
						size={64}
						src={`${process.env.REACT_APP_IMAGES_URL}/authors/${
							author.id
						}?cache=${imageCache.toISOString()}`}
						icon={<UserOutlined />}
					/>
				}
				title={`${author.name.en} / ${author.name.yi}`}
				description={`id:${author.id}`}
			/>
		</List.Item>
	);
};

import { Tag } from 'antd';
import { PresetColorType } from 'antd/es/_util/colors';
import { ColumnsType } from 'antd/es/table';
import { ColumnFilterItem } from 'antd/es/table/interface';
import { CategoryUploadStatusEnum } from 'modules/category/enums/categoryUploadStatus';
import { UploadedCategoryInfoType } from 'modules/category/types/uploadedCategoryInfo';
import { useTranslation } from 'react-i18next';

const colorByStatus: { [key in CategoryUploadStatusEnum]: PresetColorType } = {
	[CategoryUploadStatusEnum.SUCCESS]: 'green',
	[CategoryUploadStatusEnum.ERROR_CATEGORY]: 'red',
	[CategoryUploadStatusEnum.ERROR_ITEMS]: 'orange',
};

export const useColumns = (): ColumnsType<UploadedCategoryInfoType> => {
	const { t } = useTranslation('category', {
		keyPrefix: 'UploadedCategoryTable',
	});

	const statusFilterOptions: ColumnFilterItem[] = Object.values(
		CategoryUploadStatusEnum
	).map((status) => ({
		text: t(status),
		value: status,
	}));

	return [
		{
			key: 'id',
			dataIndex: 'categoryId',
			title: t('categoryId'),
			render: (_, rowData) => rowData.categoryId,
		},
		{
			key: 'status',
			dataIndex: 'status',
			title: t('status'),
			filters: statusFilterOptions,
			render: (_, rowData) => (
				<Tag color={colorByStatus[rowData.status]}>{t(rowData.status)}</Tag>
			),
		},
		{
			width: 300,
			dataIndex: 'error',
			title: t('error'),
			ellipsis: true,
			render: (_, rowData) => rowData.error || '-',
		},
	];
};

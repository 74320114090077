import { yupResolver } from '@hookform/resolvers/yup';
import { Row, Col, Button, message } from 'antd';
import { AuthorType } from 'modules/author/types';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { ValidationSchemaType, schema } from './schema';
import {
	ControlledCheckbox,
	ControlledFileUploadButton,
	ControlledInput,
} from 'modules/common/components';
import { useTranslation } from 'react-i18next';
import { useCreateAuthorMutation } from 'modules/author/requests/mutations';
import { UploadOutlined } from '@ant-design/icons';

type PropsType = {
	author?: AuthorType;
};

export const AuthorForm: FC<PropsType> = ({ author }) => {
	const { t } = useTranslation('author', { keyPrefix: 'AuthorForm' });
	const { control, handleSubmit } = useForm<ValidationSchemaType>({
		resolver: yupResolver(schema),
		values: {
			nameEng: author?.name.en || '',
			nameHeb: author?.name.he,
			isNassi: author?.isNassi,
		},
	});
	const [createAuthorMutation] = useCreateAuthorMutation();

	const createAuthor = async (data: ValidationSchemaType) => {
		try {
			await createAuthorMutation({
				nameEng: data.nameEng,
				nameHeb: data.nameHeb,
				isNassi: data.isNassi,
				file: data.fileList?.[0],
			});
			message.success(t('authorCreated'));
		} catch (e) {
			message.error(t('errorWhileCreatingAuthor'));
		}
	};

	//TODO will be implemented
	// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
	const updateAuthor = async (data: ValidationSchemaType) => {};

	const submit = handleSubmit(author?.id ? updateAuthor : createAuthor);

	return (
		<Row justify={'end'} gutter={[24, 24]}>
			<Col span={24}>
				{t('nameEng')}:
				<ControlledInput
					useControllerProps={{
						control,
						name: 'nameEng',
					}}
				/>
			</Col>
			<Col span={24}>
				{t('nameHeb')}:
				<ControlledInput
					useControllerProps={{
						control,
						name: 'nameHeb',
					}}
				/>
			</Col>
			<Col span={24}>
				{t('isNassi')}:{' '}
				<ControlledCheckbox
					useControllerProps={{
						control,
						name: 'isNassi',
					}}
				/>
			</Col>
			<Col span={24}>
				{t('file')}:
				<ControlledFileUploadButton
					useControllerProps={{
						control,
						name: 'fileList',
					}}
					fileUploadProps={{
						listType: 'picture-card',
						beforeUpload: () => false,
						maxCount: 1,
					}}
					content={<Button type='link' icon={<UploadOutlined />} />}
				/>
			</Col>
			<Col>
				<Button type='primary' onClick={submit}>
					{t('submit')}
				</Button>
			</Col>
		</Row>
	);
};

import { hmApi } from 'store/api';
import { GetUsersType } from '../../types';
import { GetUsersResponseType } from '../../types/getUser';

export const { useGetUsersQuery } = hmApi.injectEndpoints({
	endpoints: (build) => ({
		getUsers: build.query<GetUsersResponseType, GetUsersType>({
			query: (options) => ({
				url: '/users',
				params: options,
			}),
		}),
	}),
});
